import * as types from "../mutation-types";
import etiquetaAPI from "@/api/etiqueta";

export const state = {
    etiquetas: [],
    loadingEtiquetas: false,
    currentEtiqueta: null,
    showModalDeleteEtiqueta: false
};

export const actions = {
    getEtiquetas({ commit }, payload) {
        commit(types.REPLACE_LOADING_ETIQUETAS, { status: true });
        return new Promise((resolve, reject) => {
            etiquetaAPI
                .get(payload)
                .then(response => {
                    const etiquetas = response.data.data;

                    commit(types.REPLACE_LOADING_ETIQUETAS, { status: false });
                    commit(types.REPLACE_ETIQUETAS, { etiquetas });

                    resolve(response);
                })
                .catch(error => {
                    commit(types.REPLACE_LOADING_ETIQUETAS, { status: false });
                    reject(error);
                });
        });
    },

    getEtiqueta({ commit }, payload) {
        return new Promise((resolve, reject) => {
            etiquetaAPI
                .getById(payload)
                .then(response => {
                    const etiqueta = response.data.data;

                    commit(types.REPLACE_CURRENT_ETIQUETA, { etiqueta });

                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    deleteEtiqueta({ commit }, payload) {
        return new Promise((resolve, reject) => {
            etiquetaAPI
                .delete(payload)
                .then(response => {
                    this._vm.$notify.success({
                        title: "Info",
                        message: "La etiqueta ha sido eliminado con éxito."
                    });
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    replaceCurrentEtiqueta({ commit }, payload) {
        commit(types.REPLACE_CURRENT_ETIQUETA, payload);
    },
    replaceShowModalDeleteEtiqueta({ commit }, payload) {
        commit(types.REPLACE_SHOW_MODAL_DELETE_ETIQUETA, payload);
    },
    updateEtiqueta({ commit }, payload) {
        return new Promise((resolve, reject) => {
            etiquetaAPI
                .put(payload)
                .then(response => {
                    this._vm.$notify.success({
                        title: "Etiqueta",
                        message: "El categoria ha sido actualizado con éxito."
                    });

                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    createEtiqueta({ commit }, payload) {
        return new Promise((resolve, reject) => {
            etiquetaAPI
                .post(payload)
                .then(response => {
                    this._vm.$notify.success({
                        title: "Etiqueta",
                        message: "El etiqueta ha sido actualizado con éxito."
                    });

                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
};

export const mutations = {
    [types.REPLACE_LOADING_ETIQUETAS](state, { status }) {
        state.loadingEtiquetas = status;
    },
    [types.REPLACE_ETIQUETAS](state, { etiquetas }) {
        state.etiquetas = etiquetas;
    },
    [types.REPLACE_CURRENT_ETIQUETA](state, { etiqueta }) {
        state.currentEtiqueta = etiqueta;
    },
    [types.REPLACE_SHOW_MODAL_DELETE_ETIQUETA](state, { status }) {
        state.showModalDeleteEtiqueta = status;
    }
};