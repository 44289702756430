import imageAPI from "@/api/image";
import * as types from "../mutation-types";
export const state = {
    currentImagen: null,
    showModalDeleteImagen: false
};
export const actions = {
    uploadImagen({ commit }, payload) {
        return new Promise((resolve, reject) => {
            imageAPI.uploadImagen(payload)
                .then(response => {
                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
    getImagen({ commit }, payload) {
        return new Promise((resolve, reject) => {
            imageAPI
                .getById(payload)
                .then(response => {
                    const imagen = response.data.data;

                    commit(types.REPLACE_CURRENT_IMAGEN, { imagen });

                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    deleteImagen({ commit }, payload) {
        return new Promise((resolve, reject) => {
            imageAPI
                .delete(payload)
                .then(response => {
                    this._vm.$notify.success({
                        title: "Info",
                        message: "La Imagen ha sido eliminado con éxito."
                    });
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    replaceCurrentImagen({ commit }, payload) {
        commit(types.REPLACE_CURRENT_IMAGEN, payload);
    },
    replaceShowModalDeleteImagen({ commit }, payload) {
        commit(types.REPLACE_SHOW_MODAL_DELETE_IMAGEN, payload);
    },
    updateImagen({ commit }, payload) {
        return new Promise((resolve, reject) => {
            imageAPI
                .put(payload)
                .then(response => {
                    this._vm.$notify.success({
                        title: "Info",
                        message: "El Item ha sido actualizado con éxito."
                    });

                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    }
}

export const mutations = {
    [types.REPLACE_CURRENT_IMAGEN](state, { imagen }) {
        state.currentImagen = imagen;
    },
    [types.REPLACE_SHOW_MODAL_DELETE_IMAGEN](state, { status }) {
        state.showModalDeleteImagen = status;
    }
};