// auth.js
export const LOGOUT = "LOGOUT";
export const SAVE_TOKEN = "SAVE_TOKEN";
export const FETCH_USER = "FETCH_USER";
export const FETCH_USER_SUCCESS = "FETCH_USER_SUCCESS";
export const FETCH_USER_FAILURE = "FETCH_USER_FAILURE";
export const UPDATE_USER = "UPDATE_USER";

// primary drawer
export const REPLACE_PRIMARY_DRAWER = "REPLACE_PRIMARY_DRAWER";

// type users
export const REPLACE_LOADING_TYPE_USERS = "REPLACE_LOADING_TYPE_USERS";
export const REPLACE_TYPE_USERS = "REPLACE_TYPE_USERS";

// users
export const REPLACE_SHOW_MODAL_LOGIN = "REPLACE_SHOW_MODAL_LOGIN";
export const REPLACE_LOADING_USERS = "REPLACE_LOADING_USERS";
export const REPLACE_USERS = "REPLACE_USERS";
export const REPLACE_SHOW_MODAL_DELETE_USER = "REPLACE_SHOW_MODAL_DELETE_USER";
export const REPLACE_CURRENT_USER = "REPLACE_CURRENT_USER";
export const REPLACE_LOADING_CURRENT_USER = "REPLACE_LOADING_CURRENT_USER";

// tags
export const REPLACE_LOADING_TAGS = "REPLACE_LOADING_TAGS";
export const REPLACE_TAGS = "REPLACE_TAGS";
export const REPLACE_SHOW_MODAL_DELETE_TAG = "REPLACE_SHOW_MODAL_DELETE_TAG";
export const REPLACE_CURRENT_TAG = "REPLACE_CURRENT_TAG";
export const REPLACE_LOADING_CURRENT_TAG = "REPLACE_LOADING_CURRENT_TAG";

// administradores
export const REPLACE_LOADING_ADMINISTRADORES = "REPLACE_LOADING_ADMINISTRADORES";
export const REPLACE_ADMINISTRADORES = "REPLACE_ADMINISTRADORES";
export const REPLACE_CURRENT_ADMINISTRADOR = "REPLACE_CURRENT_ADMINISTRADOR";

// notificaciones
export const REPLACE_LOADING_NOTIFICACIONES = "REPLACE_LOADING_NOTIFICACIONES";
export const REPLACE_NOTIFICACIONES = "REPLACE_NOTIFICACIONES";

// categorias
export const REPLACE_LOADING_CATEGORIAS = "REPLACE_LOADING_CATEGORIAS";
export const REPLACE_CATEGORIAS = "REPLACE_CATEGORIAS";
export const REPLACE_CURRENT_CATEGORIA = "REPLACE_CURRENT_CATEGORIA";
export const REPLACE_SHOW_MODAL_DELETE_CATEGORIA = "REPLACE_SHOW_MODAL_DELETE_CATEGORIA";

// etiquetas
export const REPLACE_LOADING_ETIQUETAS = "REPLACE_LOADING_ETIQUETAS";
export const REPLACE_ETIQUETAS = "REPLACE_ETIQUETAS";
export const REPLACE_CURRENT_ETIQUETA = "REPLACE_CURRENT_ETIQUITA";
export const REPLACE_SHOW_MODAL_DELETE_ETIQUETA = "REPLACE_SHOW_MODAL_DELETE_ETIQUETA";

// productos
export const REPLACE_LOADING_PRODUCTOS = "REPLACE_LOADING_PRODUCTOS";
export const REPLACE_PRODUCTOS = "REPLACE_PRODUCTOS";
export const REPLACE_CURRENT_PRODUCTO = "REPLACE_CURRENT_PRODUCTO";
export const REPLACE_SHOW_MODAL_DELETE_PRODUCTO = "REPLACE_SHOW_MODAL_DELETE_PRODUCTO";
export const REPLACE_LOADING_PRODUCTO_IMAGES = "REPLACE_LOADING_PRODUCTO_IMAGES";
export const REPLACE_PRODUCTO_IMAGES = "REPLACE_PRODUCTO_IMAGES";
export const REPLACE_LOADING_PRODUCTO_ATRIBUTOS = "REPLACE_LOADING_PRODUCTO_ATRIBUTOS";
export const REPLACE_PRODUCTO_ATRIBUTOS = "REPLACE_PRODUCTO_ATRIBUTOS";
export const REPLACE_LOADING_PRODUCTO_ITEMS = "REPLACE_LOADING_PRODUCTO_ITEMS";
export const REPLACE_PRODUCTO_ITEMS = "REPLACE_PRODUCTO_ITEMS";
export const REPLACE_CURRENT_ITEM = "REPLACE_CURRENT_ITEM";
export const REPLACE_SHOW_MODAL_DELETE_ITEM = "REPLACE_SHOW_MODAL_DELETE_ITEM";
export const REPLACE_CURRENT_IMAGEN = "REPLACE_CURRENT_IMAGEN";
export const REPLACE_SHOW_MODAL_DELETE_IMAGEN = "REPLACE_SHOW_MODAL_DELETE_IMAGEN";
export const REPLACE_LOADING_PRECIOS = "REPLACE_LOADING_PRECIOS";
export const REPLACE_PRECIOS = "REPLACE_PRECIOS";
export const REPLACE_CURRENT_DIMENSION = "REPLACE_CURRENT_DIMENSION";
export const REPLACE_CURRENT_PRECIO = "REPLACE_CURRENT_PRECIO";
export const REPLACE_SHOW_MODAL_DELETE_PRECIO = "REPLACE_SHOW_MODAL_DELETE_PRECIO";
export const REPLACE_CURRENT_ATRIBUTO = "REPLACE_CURRENT_ATRIBUTO";
export const REPLACE_SHOW_MODAL_DELETE_ATRIBUTO = "REPLACE_SHOW_MODAL_DELETE_ATRIBUTO";
export const REPLACE_PRODUCTO_ETIQUETAS = "REPLACE_PRODUCTO_ETIQUETAS";
export const REPLACE_LOADING_PRODUCTO_ETIQUETAS = "REPLACE_LOADING_PRODUCTO_ETIQUETAS";
export const REPLACE_ETIQUETA = "REPLACE_ETIQUETA";
export const REPLACE_CURRENT_PRODUCTO_ETIQUETA = "REPLACE_CURRENT_PRODUCTO_ETIQUETA";
export const REPLACE_SHOW_MODAL_DELETE_PRODUCTO_ETIQUETA =
  "REPLACE_SHOW_MODAL_DELETE_PRODUCTO_ETIQUETA";
export const REPLACE_CURRENT_PRODUCTO_ITEM = "REPLACE_CURRENT_PRODUCTO_ITEM";
export const REPLACE_SHOW_MODAL_DELETE_PRODUCTO_ITEM = "REPLACE_SHOW_MODAL_DELETE_PRODUCTO_ITEM";
export const REPLACE_SHOW_MODAL_ITEMS = "REPLACE_SHOW_MODAL_ITEMS";
export const REPLACE_SHOW_PRODUCT = "REPLACE_SHOW_PRODUCT";
// bodegas
export const REPLACE_LOADING_BODEGAS = "REPLACE_LOADING_BODEGAS";
export const REPLACE_BODEGAS = "REPLACE_BODEGAS";
export const REPLACE_CURRENT_BODEGA = "REPLACE_CURRENT_BODEGA";
export const REPLACE_SHOW_MODAL_DELETE_BODEGA = "REPLACE_SHOW_MODAL_DELETE_BODEGA";
export const REPLACE_LOADING_MOVIMIENTOS = "REPLACE_LOADING_MOVIMIENTOS";
export const REPLACE_MOVIMIENTOS = "REPLACE_MOVIMIENTOS";
export const REPLACE_CURRENT_DETALLE_MOVIMIENTO = "REPLACE_CURRENT_DETALLE_MOVIMIENTO";
export const REPLACE_CURRENT_MOVIMIENTO = "REPLACE_CURRENT_MOVIMIENTO";
export const REPLACE_SHOW_MODAL_DELETE_MOVIMIENTO = "REPLACE_SHOW_MODAL_DELETE_MOVIMIENTO";
export const REPLACE_PRODUCTOS_STOCK = "REPLACE_PRODUCTOS_STOCK";

// portadas
export const REPLACE_CURRENT_PORTADA = "REPLACE_CURRENT_PORTADA";
export const REPLACE_SHOW_MODAL_DELETE_PORTADA = "REPLACE_SHOW_MODAL_DELETE_PORTADA";
export const REPLACE_LOADING_PORTADAS = "REPLACE_LOADING_PORTADAS";
export const REPLACE_PORTADAS = "REPLACE_PORTADAS";

// paises
export const REPLACE_CURRENT_PAIS = "REPLACE_CURRENT_PAIS";
export const REPLACE_SHOW_MODAL_DELETE_PAIS = "REPLACE_SHOW_MODAL_DELETE_PAIS";
export const REPLACE_LOADING_PAISES = "REPLACE_LOADING_PAISES";
export const REPLACE_PAISES = "REPLACE_PAISES";

// ciudades
export const REPLACE_CURRENT_CITY = "REPLACE_CURRENT_CITY";
export const REPLACE_SHOW_MODAL_DELETE_CITY = "REPLACE_SHOW_MODAL_DELETE_CITY";
export const REPLACE_LOADING_CITIES = "REPLACE_LOADING_CITIES";
export const REPLACE_CITIES = "REPLACE_CITIES";

// clasificaciones
export const REPLACE_CURRENT_CLASIFICACION = "REPLACE_CURRENT_CLASIFICACION";
export const REPLACE_SHOW_MODAL_DELETE_CLASIFICACION = "REPLACE_SHOW_MODAL_DELETE_CLASIFICACION";
export const REPLACE_LOADING_CLASIFICACIONES = "REPLACE_LOADING_CLASIFICACIONES";
export const REPLACE_CLASIFICACIONES = "REPLACE_CLASIFICACIONES";

// items
export const REPLACE_CURRENT_ITEM_IMAGENES = "REPLACE_CURRENT_ITEM_IMAGENES";
export const REPLACE_CURRENT_ITEM_IMAGEN = "REPLACE_CURRENT_ITEM_IMAGEN";
export const REPLACE_SHOW_MODAL_DELETE_ITEM_IMAGEN = "REPLACE_SHOW_MODAL_DELETE_ITEM_IMAGEN";
export const REPLACE_SHOW_MODAL_ITEM = "REPLACE_SHOW_MODAL_ITEM";
export const REPLACE_CURRENT_ITEM_STOCKS = "REPLACE_CURRENT_ITEM_STOCKS";
// etapas
export const REPLACE_CURRENT_ETAPA = "REPLACE_CURRENT_ETAPA";
export const REPLACE_SHOW_MODAL_DELETE_ETAPA = "REPLACE_SHOW_MODAL_DELETE_ETAPA";
export const REPLACE_LOADING_ETAPAS = "REPLACE_LOADING_ETAPAS";
export const REPLACE_ETAPAS = "REPLACE_ETAPAS";

// clientes
export const REPLACE_CURRENT_CLIENTE = "REPLACE_CURRENT_CLIENTE";
export const REPLACE_SHOW_MODAL_DELETE_CLIENTE = "REPLACE_SHOW_MODAL_DELETE_CLIENTE";
export const REPLACE_LOADING_CLIENTES = "REPLACE_LOADING_CLIENTES";
export const REPLACE_CLIENTES = "REPLACE_CLIENTES";
export const REPLACE_CURRENT_STADISTICS = "REPLACE_CURRENT_STADISTICS";
export const REPLACE_CURRENT_CARS = "REPLACE_CURRENT_CARS";
export const REPLACE_CURRENT_CARS_ITEMS = "REPLACE_CURRENT_CARS_ITEMS";
export const REPLACE_DETAIL_CART = "REPLACE_DETAIL_CART";
export const REPLACE_SHOW_MODAL_DETAIL_CLIENTE = "REPLACE_SHOW_MODAL_DETAIL_CLIENTE";
export const REPLACE_CURRENT_DIRECCIONES = "REPLACE_CURRENT_DIRECCIONES";
export const REPLACE_REPORTE_CLIENTES = "REPLACE_REPORTE_CLIENTES";
export const REPLACE_REPORTE_ITEMS = "REPLACE_REPORTE_ITEMS";
export const REPLACE_LOADING_REPORTE_CLIENTES = "REPLACE_LOADING_REPORTE_CLIENTES";
export const REPLACE_LOADING_REPORTE_ITEM = "REPLACE_LOADING_REPORTE_ITEM";
export const REPLACE_LOADING_REPORTE_CIUDADES = "REPLACE_LOADING_REPORTE_CIUDADES";
export const REPLACE_LOADING_REPORTE_SIN_STOCKS = "REPLACE_LOADING_REPORTE_SIN_STOCKS";
export const REPLACE_REPORTE_SIN_STOCKS = "REPLACE_REPORTE_SIN_STOCKS";
export const REPLACE_REPORTE_CIUDADES = "REPLACE_REPORTE_CIUDADES";
export const REPLACE_LOADING_REPORTE_STOCK = "REPLACE_LOADING_REPORTE_STOCK";
export const REPLACE_REPORTE_STOCK = "REPLACE_REPORTE_STOCK";

// cupones
export const REPLACE_CURRENT_CUPON = "REPLACE_CURRENT_CUPON";
export const REPLACE_SHOW_MODAL_DELETE_CUPON = "REPLACE_SHOW_MODAL_DELETE_CUPON";
export const REPLACE_LOADING_CUPONES = "REPLACE_LOADING_CUPONES";
export const REPLACE_CUPONES = "REPLACE_CUPONES";

// couriers
export const REPLACE_LOADING_COURIERS = "REPLACE_LOADING_COURIERS";
export const REPLACE_COURIERS = "REPLACE_COURIERS";
export const REPLACE_CURRENT_COURIER = "REPLACE_CURRENT_COURIER";
export const REPLACE_SHOW_MODAL_DELETE_COURIER = "REPLACE_SHOW_MODAL_DELETE_COURIER";

// coberturas
export const REPLACE_LOADING_COBERTURAS = "REPLACE_LOADING_COBERTURAS";
export const REPLACE_COBERTURAS = "REPLACE_COBERTURAS";
export const REPLACE_CURRENT_COBERTURA = "REPLACE_CURRENT_COBERTURA";
export const REPLACE_SHOW_MODAL_DELETE_COBERTURA = "REPLACE_SHOW_MODAL_DELETE_COBERTURA";

// descuentos
export const REPLACE_CURRENT_DESCUENTO = "REPLACE_CURRENT_DESCUENTO";
export const REPLACE_SHOW_MODAL_DELETE_DESCUENTO = "REPLACE_SHOW_MODAL_DELETE_DESCUENTO";
export const REPLACE_LOADING_DESCUENTOS = "REPLACE_LOADING_DESCUENTOS";
export const REPLACE_DESCUENTOS = "REPLACE_DESCUENTOS";

// rating
export const REPLACE_CURRENT_RATING = "REPLACE_CURRENT_RATING";
export const REPLACE_LOADING_RATINGS = "REPLACE_LOADING_RATINGS";
export const REPLACE_RATINGS = "REPLACE_RATINGS";
export const REPLACE_SHOW_MODAL_DELETE_RATING = "REPLACE_SHOW_MODAL_DELETE_RATING";

// configurations
export const REPLACE_LOADING_CONFIGURACIONES = "REPLACE_LOADING_CONFIGURACIONES";
export const REPLACE_CONFIGURACIONES = "REPLACE_CONFIGURACIONES";
export const REPLACE_CURRENT_CONFIGURACION = "REPLACE_CURRENT_CONFIGURACION";

// carros
export const REPLACE_LOADING_CARROS = "REPLACE_LOADING_CARROS";
export const REPLACE_CARROS = "REPLACE_CARROS";
export const REPLACE_SHOW_MODAL_DETAIL_CART = "REPLACE_SHOW_MODAL_DETAIL_CART";
