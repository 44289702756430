import cotizacionAPI from "@/api/cotizacion";

export const actions = {
  createCotizacion({ commit }, payload) {
    return new Promise((resolve, reject) => {
      cotizacionAPI
        .post(payload)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  }
};
