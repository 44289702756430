import * as types from "../mutation-types";
import bodegaAPI from "@/api/bodega";

export const state = {
    bodegas: [],
    loadingBodegas: false,
    currentBodega: null,
    showModalDeleteBodega: false,
    movimientos: [],
    loadingMovimientos: false,
    detalleMovimiento: false,
    movimiento: null,
    showModalDeleteMovimiento: false,
    stockProductos: []
};

export const actions = {
    getBodegas({ commit }, payload) {
        commit(types.REPLACE_LOADING_BODEGAS, { status: true });
        return new Promise((resolve, reject) => {
            bodegaAPI
                .get(payload)
                .then(response => {
                    const bodegas = response.data.data;

                    commit(types.REPLACE_LOADING_BODEGAS, { status: false });
                    commit(types.REPLACE_BODEGAS, { bodegas });

                    resolve(response);
                })
                .catch(error => {
                    commit(types.REPLACE_LOADING_BODEGAS, { status: false });
                    reject(error);
                });
        });
    },

    getBodega({ commit }, payload) {
        return new Promise((resolve, reject) => {
            bodegaAPI
                .getById(payload)
                .then(response => {
                    const bodega = response.data.data;
                    commit(types.REPLACE_CURRENT_BODEGA, { bodega });
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    deleteBodega({ commit }, payload) {
        return new Promise((resolve, reject) => {
            bodegaAPI
                .delete(payload)
                .then(response => {
                    this._vm.$notify.success({
                        title: "Info",
                        message: "La bodega ha sido eliminado con éxito."
                    });
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    replaceCurrentBodega({ commit }, payload) {
        commit(types.REPLACE_CURRENT_BODEGA, payload);
    },
    replaceShowModalDeleteBodega({ commit }, payload) {
        commit(types.REPLACE_SHOW_MODAL_DELETE_BODEGA, payload);
    },
    updateBodega({ commit }, payload) {
        return new Promise((resolve, reject) => {
            bodegaAPI
                .put(payload)
                .then(response => {
                    this._vm.$notify.success({
                        title: "Bodega",
                        message: "La Bodega ha sido actualizado con éxito."
                    });
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    createBodega({ commit }, payload) {
        return new Promise((resolve, reject) => {
            bodegaAPI
                .post(payload)
                .then(response => {
                    this._vm.$notify.success({
                        title: "Bodega",
                        message: "La bodega ha sido actualizado con éxito."
                    });
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    createMovimiento({ commit }, payload) {
        return new Promise((resolve, reject) => {
            bodegaAPI
                .postMovimiento(payload)
                .then(response => {
                    this._vm.$notify.success({
                        title: "Bodega",
                        message: "El Movimiento se realizo  con éxito."
                    });
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    getMovimientos({ commit }, payload) {
        commit(types.REPLACE_LOADING_MOVIMIENTOS, { status: true });
        return new Promise((resolve, reject) => {
            bodegaAPI
                .getByIdMovimientos(payload)
                .then(response => {
                    const movimientos = response.data.data;

                    commit(types.REPLACE_LOADING_MOVIMIENTOS, { status: false });
                    commit(types.REPLACE_MOVIMIENTOS, { movimientos });

                    resolve(response);
                })
                .catch(error => {
                    commit(types.REPLACE_LOADING_MOVIMIENTOS, { status: false });
                    reject(error);
                });
        });
    },
    getProductosStock({ commit }, payload) {
        return new Promise((resolve, reject) => {
            bodegaAPI
                .getProductos(payload)
                .then(response => {
                    const stockProductos = response.data.data;
                    commit(types.REPLACE_PRODUCTOS_STOCK, { stockProductos });
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    getDetalleMovimiento({ commit }, payload) {
        return new Promise((resolve, reject) => {
            bodegaAPI
                .getDetalleMovimiento(payload)
                .then(response => {
                    const detalle = response.data.data;
                    commit(types.REPLACE_CURRENT_DETALLE_MOVIMIENTO, { detalle });
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    deleteMovimiento({ commit }, payload) {
        return new Promise((resolve, reject) => {
            bodegaAPI
                .deleteMovimiento(payload)
                .then(response => {
                    this._vm.$notify.success({
                        title: "Info",
                        message: "El movimiento ha sido eliminado con éxito."
                    });
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    replaceCurrentMovimiento({ commit }, payload) {
        commit(types.REPLACE_CURRENT_MOVIMIENTO, payload);
    },
    replaceShowModalDeleteMovimiento({ commit }, payload) {
        commit(types.REPLACE_SHOW_MODAL_DELETE_MOVIMIENTO, payload);
    }
};

export const mutations = {
    [types.REPLACE_LOADING_BODEGAS](state, { status }) {
        state.loadingBodegas = status;
    },
    [types.REPLACE_BODEGAS](state, { bodegas }) {
        state.bodegas = bodegas;
    },
    [types.REPLACE_PRODUCTOS_STOCK](state, { stockProductos }) {
        state.stockProductos = stockProductos;
    },
    [types.REPLACE_CURRENT_BODEGA](state, { bodega }) {
        state.currentBodega = bodega;
    },
    [types.REPLACE_SHOW_MODAL_DELETE_BODEGA](state, { status }) {
        state.showModalDeleteBodega = status;
    },
    [types.REPLACE_LOADING_MOVIMIENTOS](state, { status }) {
        state.loadingMovimientos = status;
    },
    [types.REPLACE_MOVIMIENTOS](state, { movimientos }) {
        state.movimientos = movimientos;
    },
    [types.REPLACE_CURRENT_DETALLE_MOVIMIENTO](state, { detalle }) {
        state.currentDetalle = detalle;
    },
    [types.REPLACE_CURRENT_MOVIMIENTO](state, { movimiento }) {
        state.movimiento = movimiento;
    },
    [types.REPLACE_SHOW_MODAL_DELETE_MOVIMIENTO](state, { status }) {
        state.showModalDeleteMovimiento = status;
    }
};