import * as types from "../mutation-types";
import productoAPI from "@/api/producto";

export const state = {
    productos: [],
    loadingProductos: false,
    currentProducto: null,
    showModalDeleteProducto: false,
    producto: null,
    imagenes: [],
    loadingImagenes: false,
    currentImagen: null,
    atributos: [],
    loadingAtributos: false,
    items: [],
    loadingItems: false,
    productoItem: null,
    showModalDeleteProductoItem: false,
    atributo: null,
    showModalDeleteAtributo: false,
    etiquetas: [],
    loadingEtiquetas: false,
    etiqueta: null,
    showModalDeleteEtiqueta: false,
    showModalItems: false,
    showProduct: false
};

export const actions = {
    getProductos({ commit }, payload) {
        commit(types.REPLACE_LOADING_PRODUCTOS, { status: true });
        return new Promise((resolve, reject) => {
            productoAPI
                .get(payload)
                .then(response => {
                    const productos = response.data.data;

                    commit(types.REPLACE_LOADING_PRODUCTOS, { status: false });
                    commit(types.REPLACE_PRODUCTOS, { productos });

                    resolve(response);
                })
                .catch(error => {
                    commit(types.REPLACE_LOADING_PRODUCTOS, { status: false });
                    reject(error);
                });
        });
    },

    getProducto({ commit }, payload) {
        return new Promise((resolve, reject) => {
            productoAPI
                .getById(payload)
                .then(response => {
                    const producto = response.data.data;

                    commit(types.REPLACE_CURRENT_PRODUCTO, { producto });

                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    deleteProducto({ commit }, payload) {
        return new Promise((resolve, reject) => {
            productoAPI
                .delete(payload)
                .then(response => {
                    this._vm.$notify.success({
                        title: "Info",
                        message: "El producto ha sido eliminado con éxito."
                    });
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    replaceCurrentProducto({ commit }, payload) {
        commit(types.REPLACE_CURRENT_PRODUCTO, payload);
    },
    replaceShowModalDeleteProducto({ commit }, payload) {
        commit(types.REPLACE_SHOW_MODAL_DELETE_PRODUCTO, payload);
    },
    replaceShowModalItems({ commit }, payload) {
        commit(types.REPLACE_SHOW_MODAL_ITEMS, payload);
    },
    replaceShowProduct({ commit }, payload) {
        commit(types.REPLACE_SHOW_PRODUCT, payload);
    },
    updateProducto({ commit }, payload) {
        return new Promise((resolve, reject) => {
            productoAPI
                .put(payload)
                .then(response => {
                    this._vm.$notify.success({
                        title: "Info",
                        message: "El Producto ha sido actualizado con éxito."
                    });

                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    createProducto({ commit }, payload) {
        return new Promise((resolve, reject) => {
            productoAPI
                .post(payload)
                .then(response => {
                    this._vm.$notify.success({
                        title: "Info",
                        message: "El Producto ha sido actualizado con éxito."
                    });

                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    postImagen({ commit }, payload) {
        return new Promise((resolve, reject) => {
            productoAPI
                .postImage(payload)
                .then(response => {
                    this._vm.$notify.success({
                        title: "Info",
                        message: "El Producto Imagen se guardo con éxito."
                    });
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    getImages({ commit }, payload) {
        commit(types.REPLACE_LOADING_PRODUCTO_IMAGES, { status: true });
        return new Promise((resolve, reject) => {
            productoAPI
                .getImages(payload)
                .then(response => {
                    const imagenes = response.data.data;

                    commit(types.REPLACE_LOADING_PRODUCTO_IMAGES, { status: false });
                    commit(types.REPLACE_PRODUCTO_IMAGES, { imagenes });

                    resolve(response);
                })
                .catch(error => {
                    commit(types.REPLACE_LOADING_PRODUCTO_IMAGES, { status: false });
                    reject(error);
                });
        });
    },
    getAtributos({ commit }, payload) {
        commit(types.REPLACE_LOADING_PRODUCTO_ATRIBUTOS, { status: true });
        return new Promise((resolve, reject) => {
            productoAPI
                .getAtributos(payload)
                .then(response => {
                    const atributos = response.data.data;

                    commit(types.REPLACE_LOADING_PRODUCTO_ATRIBUTOS, { status: false });
                    commit(types.REPLACE_PRODUCTO_ATRIBUTOS, { atributos });

                    resolve(response);
                })
                .catch(error => {
                    commit(types.REPLACE_LOADING_PRODUCTO_ATRIBUTOS, { status: false });
                    reject(error);
                });
        });
    },
    postAtributos({ commit }, payload) {
        return new Promise((resolve, reject) => {
            productoAPI
                .postAtributos(payload)
                .then(response => {
                    this._vm.$notify.success({
                        title: "Info",
                        message: "El Producto Atributos se guardo con éxito."
                    });
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    getItems({ commit }, payload) {
        commit(types.REPLACE_LOADING_PRODUCTO_ITEMS, { status: true });
        return new Promise((resolve, reject) => {
            productoAPI
                .getItems(payload)
                .then(response => {
                    const items = response.data.data;

                    commit(types.REPLACE_LOADING_PRODUCTO_ITEMS, { status: false });
                    commit(types.REPLACE_PRODUCTO_ITEMS, { items });

                    resolve(response);
                })
                .catch(error => {
                    commit(types.REPLACE_LOADING_PRODUCTO_ITEMS, { status: false });
                    reject(error);
                });
        });
    },
    postItems({ commit }, payload) {
        return new Promise((resolve, reject) => {
            productoAPI
                .postItems(payload)
                .then(response => {
                    this._vm.$notify.success({
                        title: "Info",
                        message: "El Producto Item se guardo con éxito."
                    });
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    // PRODUCTO ITEM
    replaceCurrentProductoItem({ commit }, payload) {
        commit(types.REPLACE_CURRENT_PRODUCTO_ITEM, payload);
    },
    replaceShowModalDeleteProductoItem({ commit }, payload) {
        commit(types.REPLACE_SHOW_MODAL_DELETE_PRODUCTO_ITEM, payload);
    },
    getEtiqueta({ commit }, payload) {
        return new Promise((resolve, reject) => {
            productoAPI
                .getEtiqueta(payload)
                .then(response => {
                    const etiqueta = response.data.data;

                    commit(types.REPLACE_ETIQUETA, { etiqueta });

                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    updateEtiqueta({ commit }, payload) {
        return new Promise((resolve, reject) => {
            productoAPI
                .putEtiqueta(payload)
                .then(response => {
                    this._vm.$notify.success({
                        title: "Info",
                        message: "El Etiqueta ha sido actualizado con éxito."
                    });

                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    getAtributo({ commit }, payload) {
        return new Promise((resolve, reject) => {
            productoAPI
                .getAtributo(payload)
                .then(response => {
                    const atributo = response.data.data;

                    commit(types.REPLACE_CURRENT_ATRIBUTO, { atributo });

                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    replaceCurrentAtributo({ commit }, payload) {
        commit(types.REPLACE_CURRENT_ATRIBUTO, payload);
    },
    replaceShowModalDeleteAtributo({ commit }, payload) {
        commit(types.REPLACE_SHOW_MODAL_DELETE_ATRIBUTO, payload);
    },
    updateAtributo({ commit }, payload) {
        return new Promise((resolve, reject) => {
            productoAPI
                .putATributo(payload)
                .then(response => {
                    this._vm.$notify.success({
                        title: "Info",
                        message: "El Atributo ha sido actualizado con éxito."
                    });

                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    deleteAtributo({ commit }, payload) {
        return new Promise((resolve, reject) => {
            productoAPI
                .deleteAtributo(payload)
                .then(response => {
                    this._vm.$notify.success({
                        title: "Info",
                        message: "El atributo ha sido eliminado con éxito."
                    });
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    deleteItem({ commit }, payload) {
        return new Promise((resolve, reject) => {
            productoAPI
                .deleteItem(payload)
                .then(response => {
                    this._vm.$notify.success({
                        title: "Info",
                        message: "EL Item del producto ha sido eliminado con éxito."
                    });
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    deleteEtiqueta({ commit }, payload) {
        return new Promise((resolve, reject) => {
            productoAPI
                .deleteEtiqueta(payload)
                .then(response => {
                    this._vm.$notify.success({
                        title: "Info",
                        message: "La etiqueta del producto ha sido eliminado con éxito."
                    });
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    getEtiquetas({ commit }, payload) {
        commit(types.REPLACE_LOADING_PRODUCTO_ETIQUETAS, { status: true });
        return new Promise((resolve, reject) => {
            productoAPI
                .getEtiquetas(payload)
                .then(response => {
                    const etiquetas = response.data.data;

                    commit(types.REPLACE_LOADING_PRODUCTO_ETIQUETAS, { status: false });
                    commit(types.REPLACE_PRODUCTO_ETIQUETAS, { etiquetas });

                    resolve(response);
                })
                .catch(error => {
                    commit(types.REPLACE_LOADING_PRODUCTO_ETIQUETAS, { status: false });
                    reject(error);
                });
        });
    },
    replaceCurrentEtiqueta({ commit }, payload) {
        commit(types.REPLACE_CURRENT_PRODUCTO_ETIQUETA, payload);
    },
    replaceShowModalDeleteEtiqueta({ commit }, payload) {
        commit(types.REPLACE_SHOW_MODAL_DELETE_PRODUCTO_ETIQUETA, payload);
    },
    postEtiquetas({ commit }, payload) {
        return new Promise((resolve, reject) => {
            productoAPI
                .postEtiquetas(payload)
                .then(response => {
                    this._vm.$notify.success({
                        title: "Info",
                        message: "El Producto Etiqueta se guardo con éxito."
                    });
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    }
};

export const mutations = {
    [types.REPLACE_LOADING_PRODUCTOS](state, { status }) {
        state.loadingProductos = status;
    },
    [types.REPLACE_PRODUCTOS](state, { productos }) {
        state.productos = productos;
    },
    [types.REPLACE_CURRENT_PRODUCTO](state, { producto }) {
        state.currentProducto = producto;
    },
    [types.REPLACE_SHOW_MODAL_DELETE_PRODUCTO](state, { status }) {
        state.showModalDeleteProducto = status;
    },
    [types.REPLACE_CURRENT_PRODUCTO_ITEM](state, { productoItem }) {
        state.productoItem = productoItem;
    },
    [types.REPLACE_SHOW_MODAL_DELETE_PRODUCTO_ITEM](state, { status }) {
        state.showModalDeleteProductoItem = status;
    },
    [types.REPLACE_SHOW_MODAL_ITEMS](state, { status }) {
        state.showModalItems = status;
    },
    [types.REPLACE_SHOW_PRODUCT](state, { status }) {
        state.showProduct = status;
    },
    [types.REPLACE_LOADING_PRODUCTO_ETIQUETAS](state, { status }) {
        state.loadingEtiquetas = status;
    },
    [types.REPLACE_PRODUCTO_ETIQUETAS](state, { etiquetas }) {
        state.etiquetas = etiquetas;
    },
    [types.REPLACE_LOADING_PRODUCTO_IMAGES](state, { status }) {
        state.loadingImagenes = status;
    },
    [types.REPLACE_PRODUCTO_IMAGES](state, { imagenes }) {
        state.imagenes = imagenes;
    },
    [types.REPLACE_LOADING_PRODUCTO_ATRIBUTOS](state, { status }) {
        state.loadingAtributos = status;
    },
    [types.REPLACE_PRODUCTO_ATRIBUTOS](state, { atributos }) {
        state.atributos = atributos;
    },
    [types.REPLACE_LOADING_PRODUCTO_ITEMS](state, { status }) {
        state.loadingItems = status;
    },
    [types.REPLACE_PRODUCTO_ITEMS](state, { items }) {
        state.items = items;
    },
    [types.REPLACE_CURRENT_ATRIBUTO](state, { atributo }) {
        state.atributo = atributo;
    },
    [types.REPLACE_SHOW_MODAL_DELETE_ATRIBUTO](state, { status }) {
        state.showModalDeleteAtributo = status;
    },
    [types.REPLACE_ETIQUETA](state, { etiqueta }) {
        state.etiqueta = etiqueta;
    },
    [types.REPLACE_SHOW_MODAL_DELETE_PRODUCTO_ETIQUETA](state, { status }) {
        state.showModalDeleteEtiqueta = status;
    },
    [types.REPLACE_CURRENT_PRODUCTO_ETIQUETA](state, { etiqueta }) {
        state.etiqueta = etiqueta;
    }
};