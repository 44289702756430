import * as types from "../mutation-types";
import descuentosAPI from "@/api/descuentos";

export const state = {
  descuentos: [],
  loadingDescuentos: false,
  currentDescuento: null,
  showModalDeleteDescuento: false
};

export const actions = {
  getDescuentos({ commit }, payload) {
    commit(types.REPLACE_LOADING_DESCUENTOS, { status: true });
    return new Promise((resolve, reject) => {
      descuentosAPI
        .get(payload)
        .then(response => {
          const descuentos = response.data.data;

          commit(types.REPLACE_LOADING_DESCUENTOS, { status: false });
          commit(types.REPLACE_DESCUENTOS, { descuentos });

          resolve(response);
        })
        .catch(error => {
          commit(types.REPLACE_LOADING_DESCUENTOS, { status: false });
          reject(error);
        });
    });
  },

  getDescuento({ commit }, payload) {
    return new Promise((resolve, reject) => {
      descuentosAPI
        .getById(payload)
        .then(response => {
          const descuento = response.data.data;
          commit(types.REPLACE_CURRENT_DESCUENTO, { descuento });
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  deleteDescuento({ commit }, payload) {
    return new Promise((resolve, reject) => {
      descuentosAPI
        .delete(payload)
        .then(response => {
          this._vm.$notify.success({
            title: "Descuentos",
            message: "El Descuento ha sido eliminado con éxito."
          });
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  replaceCurrentDescuento({ commit }, payload) {
    commit(types.REPLACE_CURRENT_DESCUENTO, payload);
  },
  replaceShowModalDeleteDescuento({ commit }, payload) {
    commit(types.REPLACE_SHOW_MODAL_DELETE_DESCUENTO, payload);
  },
  updateDescuento({ commit }, payload) {
    return new Promise((resolve, reject) => {
      descuentosAPI
        .put(payload)
        .then(response => {
          this._vm.$notify.success({
            title: "Descuento",
            message: "El descuento ha sido actualizado con éxito."
          });

          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  }
};

export const mutations = {
  [types.REPLACE_LOADING_DESCUENTOS](state, { status }) {
    state.loadingDescuentos = status;
  },
  [types.REPLACE_DESCUENTOS](state, { descuentos }) {
    state.descuentos = descuentos;
  },
  [types.REPLACE_CURRENT_DESCUENTO](state, { descuento }) {
    state.currentDescuento = descuento;
  },
  [types.REPLACE_SHOW_MODAL_DELETE_DESCUENTO](state, { status }) {
    state.showModalDeleteDescuento = status;
  }
};
