import axios from "axios";
import { baseUrlAPI } from "@/config/api";

const HOST = baseUrlAPI;

export default {
  get(payload = {}) {
    const params = payload.params || {};
    return new Promise((resolve, reject) => {
      axios({
        url: `${HOST}/bodegas`,
        method: "get",
        params
      })
        .then(response => resolve(response))
        .catch(error => reject(error));
    });
  },
  getById(payload = {}) {
    const bodegaId = payload.bodegaId || "";

    return new Promise((resolve, reject) => {
      axios({
        url: `${HOST}/bodegas/${bodegaId}`,
        method: "get"
      })
        .then(response => resolve(response))
        .catch(error => reject(error));
    });
  },
  put(payload = {}) {
    const bodegaId = payload.bodegaId;
    const data = payload.data || {};

    return new Promise((resolve, reject) => {
      axios({
        url: `${HOST}/bodegas/${bodegaId}`,
        method: "put",
        data
      })
        .then(response => resolve(response))
        .catch(error => reject(error));
    });
  },
  post(payload = {}) {
    const data = payload.data || {};
    return new Promise((resolve, reject) => {
      axios({
        url: `${HOST}/bodegas`,
        method: "post",
        data
      })
        .then(response => resolve(response))
        .catch(error => reject(error));
    });
  },
  delete(payload = {}) {
    const id = payload.id;
    return new Promise((resolve, reject) => {
      axios({
        url: `${HOST}/bodegas/${id}`,
        method: "delete"
      })
        .then(response => resolve(response))
        .catch(error => reject(error));
    });
  },
  getProductos(payload = {}) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${HOST}/item-productos/stock`,
        method: "get"
      })
        .then(response => resolve(response))
        .catch(error => reject(error));
    });
  },
  getByIdMovimientos(payload = {}) {
    const bodegaId = payload.bodegaId || "";

    return new Promise((resolve, reject) => {
      axios({
        url: `${HOST}/bodegas/${bodegaId}/movimientos`,
        method: "get"
      })
        .then(response => resolve(response))
        .catch(error => reject(error));
    });
  },
  postMovimiento(payload = {}) {
    const data = payload.data || {};
    return new Promise((resolve, reject) => {
      axios({
        url: `${HOST}/movimientos`,
        method: "post",
        data
      })
        .then(response => resolve(response))
        .catch(error => reject(error));
    });
  },
  getDetalleMovimiento(payload = {}) {
    const movimientoId = payload.movimientoId || "";

    return new Promise((resolve, reject) => {
      axios({
        url: `${HOST}/movimientos/${movimientoId}/detalles`,
        method: "get"
      })
        .then(response => resolve(response))
        .catch(error => reject(error));
    });
  },
  deleteMovimiento(payload = {}) {
    const id = payload.id;
    return new Promise((resolve, reject) => {
      axios({
        url: `${HOST}/movimientos/${id}`,
        method: "delete"
      })
        .then(response => resolve(response))
        .catch(error => reject(error));
    });
  }
};
