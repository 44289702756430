import * as types from "../mutation-types";
import categoriaAPI from "@/api/categoria";

export const state = {
    categorias: [],
    loadingCategorias: false,
    currentCategoria: null,
    showModalDeleteCategoria: false
};

export const actions = {
    getCategorias({ commit }, payload) {
        commit(types.REPLACE_LOADING_CATEGORIAS, { status: true });
        return new Promise((resolve, reject) => {
            categoriaAPI
                .get(payload)
                .then(response => {
                    const categorias = response.data.data;

                    commit(types.REPLACE_LOADING_CATEGORIAS, { status: false });
                    commit(types.REPLACE_CATEGORIAS, { categorias });

                    resolve(response);
                })
                .catch(error => {
                    commit(types.REPLACE_LOADING_CATEGORIAS, { status: false });
                    reject(error);
                });
        });
    },

    getCategoria({ commit }, payload) {
        return new Promise((resolve, reject) => {
            categoriaAPI
                .getById(payload)
                .then(response => {
                    const categoria = response.data.data;

                    commit(types.REPLACE_CURRENT_CATEGORIA, { categoria });

                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    deleteCategoria({ commit }, payload) {
        return new Promise((resolve, reject) => {
            categoriaAPI
                .delete(payload)
                .then(response => {
                    this._vm.$notify.success({
                        title: "Info",
                        message: "La categoria ha sido eliminado con éxito."
                    });
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    replaceCurrentCategoria({ commit }, payload) {
        commit(types.REPLACE_CURRENT_CATEGORIA, payload);
    },
    replaceShowModalDeleteCategoria({ commit }, payload) {
        commit(types.REPLACE_SHOW_MODAL_DELETE_CATEGORIA, payload);
    },
    updateCategoria({ commit }, payload) {
        return new Promise((resolve, reject) => {
            categoriaAPI
                .put(payload)
                .then(response => {
                    this._vm.$notify.success({
                        title: "Info",
                        message: "El categoria ha sido actualizado con éxito."
                    });

                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    createCategoria({ commit }, payload) {
        return new Promise((resolve, reject) => {
            categoriaAPI
                .post(payload)
                .then(response => {
                    this._vm.$notify.success({
                        title: "categoria",
                        message: "La categoria ha sido actualizado con éxito."
                    });
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    deleteCategoria({ commit }, payload) {
        return new Promise((resolve, reject) => {
            categoriaAPI
                .delete(payload)
                .then(response => {
                    this._vm.$notify.success({
                        title: "Info",
                        message: "La categoria ha sido eliminado con éxito."
                    });
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    }
};

export const mutations = {
    [types.REPLACE_LOADING_CATEGORIAS](state, { status }) {
        state.loadingCategorias = status;
    },
    [types.REPLACE_CATEGORIAS](state, { categorias }) {
        state.categorias = categorias;
    },
    [types.REPLACE_CURRENT_CATEGORIA](state, { categoria }) {
        state.currentCategoria = categoria;
    },
    [types.REPLACE_SHOW_MODAL_DELETE_CATEGORIA](state, { status }) {
        state.showModalDeleteCategoria = status;
    }
};