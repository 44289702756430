export default [
  {
    path: "/",
    name: "login",
    component: () => import("@/components/Login.vue")
  },
  {
    path: "/carts",
    name: "carts",
    component: () => import("@/views/cart/ListCart.vue")
  },
  {
    path: "/crear-categoria",
    name: "CrearCaterogia",
    component: () => import("@/views/categorias/Create.vue")
  },
  {
    path: "/lista-categoria",
    name: "ListaCategoria",
    component: () => import("@/views/categorias/List.vue")
  },
  {
    path: "/descuento",
    name: "Descuento",
    component: () => import("@/views/discount/List.vue")
  },
  {
    path: "/crear-descuento",
    name: "crearDescuento",
    component: () => import("@/views/discount/Create.vue")
  },
  {
    path: "/descuento/:id",
    name: "editDescuento",
    component: () => import("@/views/discount/Edit.vue")
  },
  {
    path: "/lista-bodegas",
    name: "ListaBodegas",
    component: () => import("@/views/bodega/List.vue")
  },
  {
    path: "/bodega/:id",
    name: "editBodega",
    component: () => import("@/views/bodega/Edit.vue")
  },
  {
    path: "/bodega/:id/movimientos",
    name: "Movimientos",
    component: () => import("@/views/bodega/MovimientosList.vue")
  },
  {
    path: "/movimiento/:id",
    name: "detalleMovimiento",
    component: () => import("@/views/bodega/DetalleMovimiento.vue")
  },
  {
    path: "/bodega/:id/crear-movimiento",
    name: "crearMovimiento",
    component: () => import("@/views/bodega/CreateMovimiento.vue")
  },
  {
    path: "/crear-bodega",
    name: "createBodega",
    component: () => import("@/views/bodega/Create.vue")
  },
  {
    path: "/categoria/:id",
    name: "EditCategoria",
    component: () => import("@/views/categorias/Edit.vue")
  },
  {
    path: "/crear-etiqueta",
    name: "CrearEtiqueta",
    component: () => import("@/views/etiquetas/Create.vue")
  },
  {
    path: "/lista-etiqueta",
    name: "ListaEtiqueta",
    component: () => import("@/views/etiquetas/List.vue")
  },
  {
    path: "/etiqueta/:id",
    name: "EditEtiqueta",
    component: () => import("@/views/etiquetas/Edit.vue")
  },
  {
    path: "/crear-producto",
    name: "CrearProducto",
    component: () => import("@/views/productos/Create.vue")
  },
  {
    path: "/lista-producto",
    name: "ListaProducto",
    component: () => import("@/views/productos/List.vue")
  },
  {
    path: "/lista-items",
    name: "listaItems",
    component: () => import("@/views/productos/ListItems.vue")
  },
  {
    path: "/listado-items",
    name: "listadoItems",
    component: () => import("@/views/items/ListadoItems.vue")
  },
  {
    path: "/producto/:id",
    name: "EditProduct",
    component: () => import("@/views/productos/Edit.vue")
  },
  {
    path: "/producto/:id/imagenes",
    name: "ListaProductImagen",
    component: () => import("@/views/productos/ListImages.vue")
  },
  {
    path: "/producto/:id/agregar-imagen",
    name: "CrearImagen",
    component: () => import("@/views/productos/CreateImage.vue")
  },
  {
    path: "/producto/:id/crear-atributos",
    name: "CrearAtributos",
    component: () => import("@/views/productos/CreateAtributos.vue")
  },
  {
    path: "/producto/:id/atributos",
    name: "ListaProductAtributo",
    component: () => import("@/views/productos/ListAtributos.vue")
  },
  {
    path: "/producto/:id/items",
    name: "ListaProductItems",
    component: () => import("@/views/productos/ListItems.vue")
  },
  {
    path: "/producto/:id/items-listado",
    name: "ListadoProductItems",
    component: () => import("@/views/items/ListadoItems.vue")
  },
  {
    path: "/producto/:id/crear-items",
    name: "CrearItems",
    component: () => import("@/views/productos/CreateItem.vue")
  },
  {
    path: "/producto-item/:id",
    name: "EditItem",
    component: () => import("@/views/productos/EditItem.vue")
  },
  {
    path: "/productos/:id/ratings",
    name: "ListRating",
    component: () => import("@/views/rating/List.vue")
  },
  {
    path: "/items-producto",
    name: "itemsProducto",
    component: () => import("@/views/itemsProducto/List.vue")
  },
  {
    path: "/producto-imagen/:id",
    name: "EditImagen",
    component: () => import("@/views/productos/EditImage.vue")
  },
  {
    path: "/producto-precios/:id",
    name: "ListaPrecios",
    component: () => import("@/views/productos/ListPrecios.vue")
  },
  {
    path: "/producto-etiquetas/:id",
    name: "ListProductoEtiquetas",
    component: () => import("@/views/productos/ListEtiquetas.vue")
  },
  {
    path: "/producto-etiquetas/:id/etiqueta",
    name: "EditProductoEtiqueta",
    component: () => import("@/views/productos/EditEtiqueta.vue")
  },
  {
    path: "/agregar-etiquetas/:id",
    name: "AgregarEtiqueta",
    component: () => import("@/views/productos/CreateEtiqueta.vue")
  },
  {
    path: "/crear-precio/:id",
    name: "CrearPrecio",
    component: () => import("@/views/productos/CreatePrecios.vue")
  },
  {
    path: "/item-dimension/:id",
    name: "ItemDimension",
    component: () => import("@/views/productos/ItemDimension.vue")
  },
  {
    path: "/producto-atributo/:id",
    name: "EditAtributo",
    component: () => import("@/views/productos/EditAtributo.vue")
  },
  {
    path: "/precio/:id",
    name: "EditPrecio",
    component: () => import("@/views/productos/EditPrecio.vue")
  },
  {
    path: "/crear-portada",
    name: "CreatePortada",
    component: () => import("@/views/portadas/Create.vue")
  },
  {
    path: "/portada/:id",
    name: "EditPortada",
    component: () => import("@/views/portadas/Edit.vue")
  },
  {
    path: "/portadas",
    name: "ListPortada",
    component: () => import("@/views/portadas/List.vue")
  },
  {
    path: "/paises",
    name: "ListPais",
    component: () => import("@/views/paises/List.vue")
  },
  {
    path: "/crear-pais",
    name: "CreatePais",
    component: () => import("@/views/paises/Create.vue")
  },
  {
    path: "/pais/:id",
    name: "EditPais",
    component: () => import("@/views/paises/Edit.vue")
  },
  {
    path: "/pais/:id/ciudades",
    name: "ListCiudad",
    component: () => import("@/views/cities/List.vue")
  },
  {
    path: "/ciudad/:id",
    name: "EditCiudad",
    component: () => import("@/views/cities/Edit.vue")
  },
  {
    path: "/pais/:id/crear-ciudad",
    name: "CreateCiudad",
    component: () => import("@/views/cities/Create.vue")
  },
  {
    path: "/crear-clasificacion",
    name: "CreateClasificacion",
    component: () => import("@/views/clasificaciones/Create.vue")
  },
  {
    path: "/clasificacion/:id",
    name: "EditClasificacion",
    component: () => import("@/views/clasificaciones/Edit.vue")
  },
  {
    path: "/clasificaciones",
    name: "ListClasificacion",
    component: () => import("@/views/clasificaciones/List.vue")
  },
  {
    path: "/lista-imagen-item/:id",
    name: "ListItemImagen",
    component: () => import("@/views/items/ListImagen.vue")
  },
  {
    path: "/item/:id/crear-imagen",
    name: "CreateImagenItem",
    component: () => import("@/views/items/CreateImagen.vue")
  },
  {
    path: "/lista-etapas",
    name: "ListaEtapas",
    component: () => import("@/views/etapas/List.vue")
  },
  {
    path: "/etapa/:id",
    name: "EditEtapa",
    component: () => import("@/views/etapas/Edit.vue")
  },
  {
    path: "/crear-etapa",
    name: "CreateEtapa",
    component: () => import("@/views/etapas/Create.vue")
  },
  {
    path: "/crear-cliente",
    name: "CreateCliente",
    component: () => import("@/views/clientes/Create.vue")
  },
  {
    path: "/list-cliente",
    name: "ListCliente",
    component: () => import("@/views/clientes/List.vue")
  },
  {
    path: "/cart/:id",
    name: "detailCart",
    component: () => import("@/views/cart/DetailCart.vue")
  },
  {
    path: "/cliente/:id",
    name: "detailCliente",
    component: () => import("@/views/clientes/Edit.vue")
  },
  {
    path: "/cliente/:id/direcciones",
    name: "direcciones",
    component: () => import("@/views/clientes/Direccion.vue")
  },
  {
    path: "/crear-usuarios",
    name: "crearUsers",
    component: () => import("@/views/users/Create.vue")
  },
  {
    path: "/listado-usuarios",
    name: "users",
    component: () => import("@/views/users/List.vue")
  },
  {
    path: "/usuario/:id",
    name: "editUsers",
    component: () => import("@/views/users/Edit.vue")
  },
  {
    path: "/producto-item/:id/stocks",
    name: "stockItem",
    component: () => import("@/views/items/StockItem.vue")
  },
  {
    path: "/reporte-items",
    name: "reporteItems",
    component: () => import("@/views/reportes/item.vue")
  },
  {
    path: "/reporte-carros",
    name: "reporteCarros",
    component: () => import("@/views/reportes/carts.vue")
  },
  {
    path: "/reporte-ciudades",
    name: "reporteCiudades",
    component: () => import("@/views/reportes/ciudad.vue")
  },
  {
    path: "/reporte-clientes",
    name: "reporteClientes",
    component: () => import("@/views/reportes/clientes.vue")
  },
  {
    path: "/reporte-sin-stock",
    name: "reporteSinStock",
    component: () => import("@/views/reportes/stocks.vue")
  },
  {
    path: "/reporte-stock",
    name: "reporteStock",
    component: () => import("@/views/reportes/stock.vue")
  },
  {
    path: "/lista-cupones",
    name: "ListaCupones",
    component: () => import("@/views/cupones/List.vue")
  },
  {
    path: "/crear-cupon",
    name: "CreateCupon",
    component: () => import("@/views/cupones/Create.vue")
  },
  {
    path: "/cupon/:id",
    name: "EditCupon",
    component: () => import("@/views/cupones/Edit.vue")
  },
  {
    path: "/crear-courier",
    name: "CreateCourier",
    component: () => import("@/views/courier/Create.vue")
  },
  {
    path: "/lista-courier",
    name: "listaCourier",
    component: () => import("@/views/courier/List.vue")
  },
  {
    path: "/courier/:id",
    name: "EditCourier",
    component: () => import("@/views/courier/Edit.vue")
  },
  {
    path: "/lista-coberturas",
    name: "listaCoberturas",
    component: () => import("@/views/coberturas/List.vue")
  },
  {
    path: "/cobertura/:id",
    name: "EditCobertura",
    component: () => import("@/views/coberturas/Edit.vue")
  },
  {
    path: "/crearCobertura",
    name: "CreateCobertura",
    component: () => import("@/views/coberturas/Create.vue")
  },
  {
    path: "/carro/:id",
    name: "CarroEtapa",
    component: () => import("@/views/cart/StepCart.vue")
  },
  {
    path: "/crear-carro",
    name: "CreateCart",
    component: () => import("@/views/cart/CreateCart.vue")
  },
  {
    path: "/cliente/:id/detalle",
    name: "ClientDetail",
    component: () => import("@/views/clientes/Detail.vue")
  },
  {
    path: "/configuraciones",
    name: "configuraciones",
    component: () => import("@/views/configuracion/List.vue")
  },
  {
    path: "/configuracion/:id",
    name: "configuracion",
    component: () => import("@/views/configuracion/Edit.vue")
  },
  {
    path: "/enviar-correos",
    name: "enviarCorreos",
    component: () => import("@/views/correos/Create.vue")
  },
  {
    path: "/reporte-ventas",
    name: "ReporteVentas",
    component: () => import("@/views/reportes/ventas.vue")
  }
];
