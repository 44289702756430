import axios from "axios";
import { baseUrlAPI } from "@/config/api";

const HOST = baseUrlAPI;

export default {
  get(payload = {}) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${HOST}/couriers`,
        method: "get"
      })
        .then(response => resolve(response))
        .catch(error => reject(error));
    });
  },
  getById(payload = {}) {
    const courierId = payload.courierId || "";

    return new Promise((resolve, reject) => {
      axios({
        url: `${HOST}/couriers/${courierId}`,
        method: "get"
      })
        .then(response => resolve(response))
        .catch(error => reject(error));
    });
  },
  put(payload = {}) {
    const courierId = payload.courierId;
    const data = payload.data || {};

    return new Promise((resolve, reject) => {
      axios({
        url: `${HOST}/couriers/${courierId}`,
        method: "patch",
        data
      })
        .then(response => resolve(response))
        .catch(error => reject(error));
    });
  },
  post(payload = {}) {
    const data = payload.data || {};
    return new Promise((resolve, reject) => {
      axios({
        url: `${HOST}/couriers`,
        method: "post",
        data
      })
        .then(response => resolve(response))
        .catch(error => reject(error));
    });
  },
  delete(payload = {}) {
    const courierId = payload.courierId;
    return new Promise((resolve, reject) => {
      axios({
        url: `${HOST}/couriers/${courierId}`,
        method: "delete"
      })
        .then(response => resolve(response))
        .catch(error => reject(error));
    });
  }
};
