import * as types from "../mutation-types";
import coberturasAPI from "@/api/coberturas";

export const state = {
    coberturas: [],
    loadingCoberturas: false,
    cobertura: null,
    showModalDeleteCobertura: false
};

export const actions = {
    getCoberturas({ commit }, payload) {
        commit(types.REPLACE_LOADING_COBERTURAS, { status: true });
        return new Promise((resolve, reject) => {
            coberturasAPI
                .get(payload)
                .then(response => {
                    const coberturas = response.data.data;

                    commit(types.REPLACE_LOADING_COBERTURAS, { status: false });
                    commit(types.REPLACE_COBERTURAS, { coberturas });

                    resolve(response);
                })
                .catch(error => {
                    commit(types.REPLACE_LOADING_COBERTURAS, { status: false });
                    reject(error);
                });
        });
    },

    getCobertura({ commit }, payload) {
        return new Promise((resolve, reject) => {
            coberturasAPI
                .getById(payload)
                .then(response => {
                    const cobertura = response.data.data;

                    commit(types.REPLACE_CURRENT_COBERTURA, { cobertura });

                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    updateCobertura({ commit }, payload) {
        return new Promise((resolve, reject) => {
            coberturasAPI
                .put(payload)
                .then(response => {
                    this._vm.$notify.success({
                        title: "Info",
                        message: "el cobertura ha sido actualizado con éxito."
                    });

                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    createCobertura({ commit }, payload) {
        return new Promise((resolve, reject) => {
            coberturasAPI
                .post(payload)
                .then(response => {
                    this._vm.$notify.success({
                        title: "Etiqueta",
                        message: "El cobertura ha sido creado con éxito."
                    });

                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    replaceCurrentCobertura({ commit }, payload) {
        commit(types.REPLACE_CURRENT_COBERTURA, payload);
    },
    replaceShowModalDeleteCobertura({ commit }, payload) {
        commit(types.REPLACE_SHOW_MODAL_DELETE_COBERTURA, payload);
    },
    deleteCobertura({ commit }, payload) {
        return new Promise((resolve, reject) => {
            coberturasAPI
                .delete(payload)
                .then(response => {
                    this._vm.$notify.success({
                        title: "Courier",
                        message: "La cobertura ha sido eliminado con éxito."
                    });
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    }
};

export const mutations = {
    [types.REPLACE_LOADING_COBERTURAS](state, { status }) {
        state.loadingPaises = status;
    },
    [types.REPLACE_COBERTURAS](state, { coberturas }) {
        state.coberturas = coberturas;
    },
    [types.REPLACE_CURRENT_COBERTURA](state, { cobertura }) {
        state.cobertura = cobertura;
    },
    [types.REPLACE_SHOW_MODAL_DELETE_COBERTURA](state, { status }) {
        state.showModalDeleteCobertura = status;
    }
};