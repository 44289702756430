import * as types from "../mutation-types";
import precioAPI from "@/api/precio";

export const state = {
    precios: [],
    loadingPrecios: false,
    precio: null,
    showModalDeletePrecio: false
};

export const actions = {
    getPrecios({ commit }, payload) {
        commit(types.REPLACE_LOADING_PRECIOS, { status: true });
        return new Promise((resolve, reject) => {
            precioAPI
                .get(payload)
                .then(response => {
                    const precios = response.data.data;

                    commit(types.REPLACE_LOADING_PRECIOS, { status: false });
                    commit(types.REPLACE_PRECIOS, { precios });

                    resolve(response);
                })
                .catch(error => {
                    commit(types.REPLACE_LOADING_PRECIOS, { status: false });
                    reject(error);
                });
        });
    },

    getPrecio({ commit }, payload) {
        return new Promise((resolve, reject) => {
            precioAPI
                .getById(payload)
                .then(response => {
                    const precio = response.data.data;

                    commit(types.REPLACE_CURRENT_PRECIO, { precio });

                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    updatePrecio({ commit }, payload) {
        return new Promise((resolve, reject) => {
            precioAPI
                .put(payload)
                .then(response => {
                    this._vm.$notify.success({
                        title: "Info",
                        message: "El precio ha sido actualizado con éxito."
                    });

                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    createPrecio({ commit }, payload) {
        return new Promise((resolve, reject) => {
            precioAPI
                .post(payload)
                .then(response => {
                    this._vm.$notify.success({
                        title: "Item Precios",
                        message: "El precio ha sido actualizado con éxito."
                    });

                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    replaceCurrentPrecio({ commit }, payload) {
        commit(types.REPLACE_CURRENT_PRECIO, payload);
    },
    replaceShowModalDeletePrecio({ commit }, payload) {
        commit(types.REPLACE_SHOW_MODAL_DELETE_PRECIO, payload);
    },
    deletePrecio({ commit }, payload) {
        return new Promise((resolve, reject) => {
            precioAPI
                .delete(payload)
                .then(response => {
                    this._vm.$notify.success({
                        title: "Info",
                        message: "El precio ha sido eliminado con éxito."
                    });
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
};

export const mutations = {
    [types.REPLACE_LOADING_PRECIOS](state, { status }) {
        state.loadingPrecios = status;
    },
    [types.REPLACE_PRECIOS](state, { precios }) {
        state.precios = precios;
    },
    [types.REPLACE_CURRENT_PRECIO](state, { precio }) {
        state.precio = precio;
    },
    [types.REPLACE_SHOW_MODAL_DELETE_PRECIO](state, { status }) {
        state.showModalDeletePrecio = status;
    }
};