import axios from "axios";
import { baseUrlAPI } from "@/config/api";

const HOST = baseUrlAPI;

export default {
	get(payload = {}) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${HOST}/clasificaciones`,
        method: "get"
      })
        .then(response => resolve(response))
        .catch(error => reject(error));
    });
  },
  getById(payload = {}) {
    const clasificacionId = payload.clasificacionId || "";

    return new Promise((resolve, reject) => {
      axios({
        url: `${HOST}/clasificaciones/${clasificacionId}`,
        method: "get"
      })
        .then(response => resolve(response))
        .catch(error => reject(error));
    });
  },
  put(payload = {}) {
    const clasificacionId = payload.clasificacionId;
    const data = payload.data || {};

    return new Promise((resolve, reject) => {
      axios({
        url: `${HOST}/clasificaciones/${clasificacionId}`,
        method: "put",
        data
      })
        .then(response => resolve(response))
        .catch(error => reject(error));
    });
  },
  post(payload = {}) {
        const data = payload.data || {};
        return new Promise((resolve, reject) => {
          axios({
            url: `${HOST}/clasificaciones`,
            method: "post",
            data
          })
            .then(response => resolve(response))
            .catch(error => reject(error));
        });
      },
      delete(payload = {}) {
        const clasificacionId = payload.clasificacionId;
        return new Promise((resolve, reject) => {
          axios({
            url: `${HOST}/clasificaciones/${clasificacionId}`,
            method: "delete",
          })
            .then(response => resolve(response))
            .catch(error => reject(error));
        });
      },
}