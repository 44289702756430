import * as types from "../mutation-types";
import itemAPI from "@/api/item";

export const state = {
    currentItem: null,
    showModalDeleteItem: false,
    dimension: null,
    itemImagenes: null,
    showModalDeleteItemImagen: false,
    currentItemImagen: null,
    showModalItem: false,
    itemStocks: []
};

export const actions = {
    getItem({ commit }, payload) {
        return new Promise((resolve, reject) => {
            itemAPI
                .getById(payload)
                .then(response => {
                    const item = response.data.data;

                    commit(types.REPLACE_CURRENT_ITEM, { item });

                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    getDimension({ commit }, payload) {
        return new Promise((resolve, reject) => {
            itemAPI
                .getDimension(payload)
                .then(response => {
                    const dimension = response.data.data;
                    commit(types.REPLACE_CURRENT_DIMENSION, { dimension });

                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    getItemStocks({ commit }, payload) {
        return new Promise((resolve, reject) => {
            itemAPI
                .getStocks(payload)
                .then(response => {
                    const stocks = response.data.data;
                    commit(types.REPLACE_CURRENT_ITEM_STOCKS, { stocks });
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    deleteEtiqueta({ commit }, payload) {
        return new Promise((resolve, reject) => {
            etiquetaAPI
                .delete(payload)
                .then(response => {
                    this._vm.$notify.success({
                        title: "Info",
                        message: "La etiqueta ha sido eliminado con éxito."
                    });
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    replaceCurrentEtiqueta({ commit }, payload) {
        commit(types.REPLACE_CURRENT_ETIQUETA, payload);
    },
    replaceShowModalDeleteEtiqueta({ commit }, payload) {
        commit(types.REPLACE_SHOW_MODAL_DELETE_ETIQUETA, payload);
    },
    replaceShowModalItem({ commit }, payload) {
        commit(types.REPLACE_SHOW_MODAL_ITEM, payload);
    },
    updateItem({ commit }, payload) {
        return new Promise((resolve, reject) => {
            itemAPI
                .put(payload)
                .then(response => {
                    this._vm.$notify.success({
                        title: "Info",
                        message: "El Item ha sido actualizado con éxito."
                    });

                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    updateDimension({ commit }, payload) {
        return new Promise((resolve, reject) => {
            itemAPI
                .putDimension(payload)
                .then(response => {
                    this._vm.$notify.success({
                        title: "Info",
                        message: "La dimension ha sido actualizado con éxito."
                    });
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    createDimension({ commit }, payload) {
        return new Promise((resolve, reject) => {
            itemAPI
                .postDimension(payload)
                .then(response => {
                    this._vm.$notify.success({
                        title: "Info",
                        message: "La dimension ha sido actualizado con éxito."
                    });

                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    getItemImages({ commit }, payload) {
        return new Promise((resolve, reject) => {
            itemAPI
                .getImages(payload)
                .then(response => {
                    const itemImagenes = response.data.data;
                    commit(types.REPLACE_CURRENT_ITEM_IMAGENES, { itemImagenes });

                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    createImagen({ commit }, payload) {
        return new Promise((resolve, reject) => {
            itemAPI
                .postImagen(payload)
                .then(response => {
                    this._vm.$notify.success({
                        title: "Info",
                        message: "La imagen  ha sido creado con éxito."
                    });

                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    replaceCurrentItemImagen({ commit }, payload) {
        commit(types.REPLACE_CURRENT_ITEM_IMAGEN, payload);
    },
    replaceShowModalDeleteItemImagen({ commit }, payload) {
        commit(types.REPLACE_SHOW_MODAL_DELETE_ITEM_IMAGEN, payload);
    },
    deleteImagen({ commit }, payload) {
        return new Promise((resolve, reject) => {
            itemAPI
                .deleteImagen(payload)
                .then(response => {
                    this._vm.$notify.success({
                        title: "Info",
                        message: "La etiqueta ha sido eliminado con éxito."
                    });
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    }
};

export const mutations = {
    [types.REPLACE_CURRENT_ITEM](state, { item }) {
        state.currentItem = item;
    },
    [types.REPLACE_SHOW_MODAL_DELETE_ETIQUETA](state, { status }) {
        state.showModalDeleteEtiqueta = status;
    },
    [types.REPLACE_SHOW_MODAL_ITEM](state, { status }) {
        state.showModalItem = status;
    },
    [types.REPLACE_CURRENT_DIMENSION](state, { dimension }) {
        state.dimension = dimension;
    },
    [types.REPLACE_CURRENT_ITEM_STOCKS](state, { stocks }) {
        state.itemStocks = stocks;
    },
    [types.REPLACE_CURRENT_ITEM_IMAGENES](state, { itemImagenes }) {
        state.itemImagenes = itemImagenes;
    },
    [types.REPLACE_CURRENT_ITEM_IMAGEN](state, { itemImagen }) {
        state.currentItemImagen = itemImagen;
        console.log(state.currentItemImagen);
    },
    [types.REPLACE_SHOW_MODAL_DELETE_ITEM_IMAGEN](state, { status }) {
        state.showModalDeleteItemImagen = status;
    }
};