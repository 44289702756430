import axios from "axios";
import { baseUrlAPI } from "@/config/api";

const HOST = baseUrlAPI;

export default {
  get(payload = {}) {
    const cartId = payload.cartId || "";
    return new Promise((resolve, reject) => {
      axios({
        url: `${HOST}/carros/${cartId}`,
        method: "get"
      })
        .then(response => resolve(response))
        .catch(error => reject(error));
    });
  },
  postEtapa(payload = {}) {
    const data = payload.data || {};
    const id = payload.id;
    return new Promise((resolve, reject) => {
      axios({
        url: `${HOST}/carros/${id}/etapas`,
        method: "post",
        data
      })
        .then(response => resolve(response))
        .catch(error => reject(error));
    });
  }
};
