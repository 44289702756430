import axios from "axios";
import { baseUrlAPI } from "@/config/api";

const HOST = baseUrlAPI;

export default {
  getById(payload = {}) {
    const itemId = payload.itemId || "";
    return new Promise((resolve, reject) => {
      axios({
        url: `${HOST}/item-productos/${itemId}`,
        method: "get"
      })
        .then(response => resolve(response))
        .catch(error => reject(error));
    });
  },
  getDimension(payload = {}) {
    const itemId = payload.itemId || "";
    return new Promise((resolve, reject) => {
      axios({
        url: `${HOST}/item-productos/${itemId}/dimension`,
        method: "get"
      })
        .then(response => resolve(response))
        .catch(error => reject(error));
    });
  },
  getStocks(payload = {}) {
    const itemId = payload.itemId || "";
    return new Promise((resolve, reject) => {
      axios({
        url: `${HOST}/item-productos/${itemId}/stocks`,
        method: "get"
      })
        .then(response => resolve(response))
        .catch(error => reject(error));
    });
  },
  put(payload = {}) {
    const itemId = payload.itemId;
    const data = payload.data || {};

    return new Promise((resolve, reject) => {
      axios({
        url: `${HOST}/item-productos/${itemId}`,
        method: "patch",
        data
      })
        .then(response => resolve(response))
        .catch(error => reject(error));
    });
  },
  putDimension(payload = {}) {
    const itemId = payload.itemId;
    const data = payload.data || {};
    return new Promise((resolve, reject) => {
      axios({
        url: `${HOST}/item-productos/${itemId}/dimension`,
        method: "patch",
        data
      })
        .then(response => resolve(response))
        .catch(error => reject(error));
    });
  },
  postDimension(payload = {}) {
    const itemId = payload.itemId;
    const data = payload.data || {};

    return new Promise((resolve, reject) => {
      axios({
        url: `${HOST}/item-productos/${itemId}/dimension`,
        method: "post",
        data
      })
        .then(response => resolve(response))
        .catch(error => reject(error));
    });
  },
  postImagen(payload = {}) {
    const itemId = payload.itemId;
    const data = payload.data || {};

    return new Promise((resolve, reject) => {
      axios({
        url: `${HOST}/item-productos/${itemId}/imagenes`,
        method: "post",
        data,
        headers: {
          "Content-Type": "multipart/form-data"
        }
      })
        .then(response => resolve(response))
        .catch(error => reject(error));
    });
  },
  getImages(payload = {}) {
    const itemId = payload.itemId || "";
    return new Promise((resolve, reject) => {
      axios({
        url: `${HOST}/item-productos/${itemId}/imagenes`,
        method: "get"
      })
        .then(response => resolve(response))
        .catch(error => reject(error));
    });
  },
  deleteImagen(payload = {}) {
    const imagenId = payload.imagenId;
    return new Promise((resolve, reject) => {
      axios({
        url: `${HOST}/imagen-items/${imagenId}`,
        method: "delete"
      })
        .then(response => resolve(response))
        .catch(error => reject(error));
    });
  }
};
