import * as types from "../mutation-types";
import paisAPI from "@/api/pais";

export const state = {
    paises: [],
    loadingPaises: false,
    pais: null,
    showModalDeletePais: false
};

export const actions = {
    getPaises({ commit }, payload) {
        commit(types.REPLACE_LOADING_PAISES, { status: true });
        return new Promise((resolve, reject) => {
            paisAPI
                .get(payload)
                .then(response => {
                    const paises = response.data.data;

                    commit(types.REPLACE_LOADING_PAISES, { status: false });
                    commit(types.REPLACE_PAISES, { paises });

                    resolve(response);
                })
                .catch(error => {
                    commit(types.REPLACE_LOADING_PAISES, { status: false });
                    reject(error);
                });
        });
    },

    getPais({ commit }, payload) {
        return new Promise((resolve, reject) => {
            paisAPI
                .getById(payload)
                .then(response => {
                    const pais = response.data.data;

                    commit(types.REPLACE_CURRENT_PAIS, { pais });

                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    updatePais({ commit }, payload) {
        return new Promise((resolve, reject) => {
            paisAPI
                .put(payload)
                .then(response => {
                    this._vm.$notify.success({
                        title: "Info",
                        message: "el pais ha sido actualizado con éxito."
                    });

                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    createPais({ commit }, payload) {
        return new Promise((resolve, reject) => {
            paisAPI
                .post(payload)
                .then(response => {
                    this._vm.$notify.success({
                        title: "Etiqueta",
                        message: "El pais ha sido creado con éxito."
                    });

                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    replaceCurrentPais({ commit }, payload) {
        commit(types.REPLACE_CURRENT_PAIS, payload);
    },
    replaceShowModalDeletePais({ commit }, payload) {
        commit(types.REPLACE_SHOW_MODAL_DELETE_PAIS, payload);
    },
    deletePais({ commit }, payload) {
        return new Promise((resolve, reject) => {
            paisAPI
                .delete(payload)
                .then(response => {
                    this._vm.$notify.success({
                        title: "Info",
                        message: "El pais ha sido eliminado con éxito."
                    });
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
};

export const mutations = {
    [types.REPLACE_LOADING_PAISES](state, { status }) {
        state.loadingPaises = status;
    },
    [types.REPLACE_PAISES](state, { paises }) {
        state.paises = paises;
    },
    [types.REPLACE_CURRENT_PAIS](state, { pais }) {
        state.pais = pais;
    },
    [types.REPLACE_SHOW_MODAL_DELETE_PAIS](state, { status }) {
        state.showModalDeletePais = status;
    }
};