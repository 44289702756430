import * as types from "../mutation-types";
import clasificacionAPI from "@/api/clasificacion";

export const state = {
    clasificaciones: [],
    loadingClasificaciones: false,
    clasificacion: null,
    showModalDeleteClasificacion: false
};

export const actions = {
    getClasificaciones({ commit }, payload) {
        commit(types.REPLACE_LOADING_CLASIFICACIONES, { status: true });
        return new Promise((resolve, reject) => {
            clasificacionAPI
                .get(payload)
                .then(response => {
                    const clasificaciones = response.data.data;

                    commit(types.REPLACE_LOADING_CLASIFICACIONES, { status: false });
                    commit(types.REPLACE_CLASIFICACIONES, { clasificaciones });

                    resolve(response);
                })
                .catch(error => {
                    commit(types.REPLACE_LOADING_CLASIFICACIONES, { status: false });
                    reject(error);
                });
        });
    },

    getClasificacion({ commit }, payload) {
        return new Promise((resolve, reject) => {
            clasificacionAPI
                .getById(payload)
                .then(response => {
                    const clasificacion = response.data.data;

                    commit(types.REPLACE_CURRENT_CLASIFICACION, { clasificacion });

                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    updateClasificacion({ commit }, payload) {
        return new Promise((resolve, reject) => {
            clasificacionAPI
                .put(payload)
                .then(response => {
                    this._vm.$notify.success({
                        title: "Info",
                        message: "el clasificacion ha sido actualizado con éxito."
                    });

                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    createClasificacion({ commit }, payload) {
        return new Promise((resolve, reject) => {
            clasificacionAPI
                .post(payload)
                .then(response => {
                    this._vm.$notify.success({
                        title: "Etiqueta",
                        message: "El clasificacion ha sido creado con éxito."
                    });

                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    replaceCurrentClasificacion({ commit }, payload) {
        commit(types.REPLACE_CURRENT_CLASIFICACION, payload);
    },
    replaceShowModalDeleteClasificacion({ commit }, payload) {
        commit(types.REPLACE_SHOW_MODAL_DELETE_CLASIFICACION, payload);
    },
    deleteClasificacion({ commit }, payload) {
        return new Promise((resolve, reject) => {
            clasificacionAPI
                .delete(payload)
                .then(response => {
                    this._vm.$notify.success({
                        title: "Info",
                        message: "El clasificacion ha sido eliminado con éxito."
                    });
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
};

export const mutations = {
    [types.REPLACE_LOADING_CLASIFICACIONES](state, { status }) {
        state.loadingClasificaciones = status;
    },
    [types.REPLACE_CLASIFICACIONES](state, { clasificaciones }) {
        state.clasificaciones = clasificaciones;
    },
    [types.REPLACE_CURRENT_CLASIFICACION](state, { clasificacion }) {
        state.clasificacion = clasificacion;
    },
    [types.REPLACE_SHOW_MODAL_DELETE_CLASIFICACION](state, { status }) {
        state.showModalDeleteClasificacion = status;
    }
};