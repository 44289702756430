import * as types from "../mutation-types";

export const state = {
    dark: false,
    primaryDrawer: {
        model: false,
        type: "default",
        clipped: false,
        floating: false,
        mini: false
    },
    footer: {
        inset: true
    }
};

export const getters = {
    sidebarItems: (state, getters, rootState, rootGetters) => {
        let items = [];
        let menuItem = {};
        if (rootGetters["auth/userIsAdmin"]) {
            return (menuItem = {
                items1: [{
                        action: "local_shipping",
                        title: "Despacho",
                        items: [
                            { title: "Coberturas", to: { name: "listaCoberturas" } },
                            { title: "Courier", to: { name: "listaCourier" } },
                            { title: "Países y Ciudades", to: { name: "ListPais" } }
                        ]
                    },
                    {
                        action: "assessment",
                        title: "Reportes",
                        items: [
                            { title: "Listado de Carros", to: { name: "reporteCarros" } },
                            { title: "Stock de productos", to: { name: "reporteStock" } },
                            { title: "Stocks a punto de quebrar", to: { name: "reporteSinStock" } },
                            { title: "Productos más vendidos", to: { name: "reporteItems" } },
                            { title: "Clientes más compradores", to: { name: "reporteClientes" } },
                            { title: "Ciudades más populares", to: { name: "reporteCiudades" } },
                            { title: "Reporte de Ventas", to: { name: "ReporteVentas" } }
                        ]
                    },
                    {
                        action: "settings",
                        title: "Configuraciones",
                        items: [
                            { title: "Banner Principal", to: { name: "ListPortada" } },
                            { title: "Etiquetas", to: { name: "ListaEtiqueta" } },
                            { title: "Etapas Carro", to: { name: "ListaEtapas" } },
                            { title: "Clasificaciones Atributos", to: { name: "ListClasificacion" } },
                            { title: "Parámetros", to: { name: "configuraciones" } },
                            { title: "Administradores", to: { name: "users" } }
                        ]
                    }
                ],
                items: [{
                        title: "Carros de Compras",
                        icon: "shopping_cart",
                        to: { name: "carts" }
                    },
                    {
                        title: "Clientes",
                        icon: "face",
                        to: { name: "ListCliente" }
                    },
                    {
                        title: "Productos",
                        icon: "description",
                        to: { name: "ListaProducto" }
                    },
                    {
                        title: "Item-productos",
                        icon: "list",
                        to: { name: "itemsProducto" }
                    },
                    {
                        title: "Categorias",
                        icon: "local_offer",
                        to: { name: "ListaCategoria" }
                    },
                    {
                        title: "Descuentos",
                        icon: "local_offer",
                        to: { name: "Descuento" }
                    },
                    {
                        title: "Bodegas",
                        icon: "store",
                        to: { name: "ListaBodegas" }
                    },
                    {
                        title: "Cupones",
                        icon: "local_play",
                        to: { name: "ListaCupones" }
                    },
                    {
                        title: "Enviar correos",
                        icon: "mail",
                        to: { name: "enviarCorreos" }
                    },
                    // {
                    //     title: "Etiquetas",
                    //     icon: "event_note",
                    //     to: { name: "ListaEtiqueta" }
                    // },
                    // {
                    //     title: "Administradores",
                    //     icon: "perm_identity",
                    //     to: { name: "users" }
                    // }
                ]
            });
        }

        if (rootGetters["auth/userIsSupervisor"]) {
            return (menuItem = {
                items1: [{
                        action: "local_shipping",
                        title: "Despacho",
                        items: [
                            { title: "Coberturas", to: { name: "listaCoberturas" } },
                            { title: "Courier", to: { name: "listaCourier" } },
                            { title: "Países y Ciudades", to: { name: "ListPais" } }
                        ]
                    },
                    {
                        action: "assessment",
                        title: "Reportes",
                        items: [
                            { title: "Listado de Carros", to: { name: "reporteCarros" } },
                            { title: "Stock de productos", to: { name: "reporteStock" } },
                            { title: "Stocks a punto de quebrar", to: { name: "reporteSinStock" } },
                            { title: "Productos más vendidos", to: { name: "reporteItems" } },
                            { title: "Clientes más compradores", to: { name: "reporteClientes" } },
                            { title: "Ciudades más populares", to: { name: "reporteCiudades" } },
                            { title: "Reporte de Ventas", to: { name: "ReporteVentas" } }
                        ]
                    },
                    {
                        action: "settings",
                        title: "Configuraciones",
                        items: [
                            { title: "Banner Principal", to: { name: "ListPortada" } },
                            { title: "Etiquetas", to: { name: "ListaEtiqueta" } },
                            { title: "Etapas Carro", to: { name: "ListaEtapas" } },
                            { title: "Clasificaciones Atributos", to: { name: "ListClasificacion" } }
                        ]
                    }
                ],
                items: [{
                        title: "Carros de Compras",
                        icon: "shopping_cart",
                        to: { name: "carts" }
                    },
                    {
                        title: "Clientes",
                        icon: "face",
                        to: { name: "ListCliente" }
                    },
                    {
                        title: "Productos",
                        icon: "description",
                        to: { name: "ListaProducto" }
                    },
                    {
                        title: "Item-productos",
                        icon: "description",
                        to: { name: "itemsProducto" }
                    },
                    {
                        title: "Categorias",
                        icon: "local_offer",
                        to: { name: "ListaCategoria" }
                    },
                    {
                        title: "Descuentos",
                        icon: "local_offer",
                        to: { name: "Descuento" }
                    },
                    {
                        title: "Enviar correos",
                        icon: "mail",
                        to: { name: "enviarCorreos" }
                    },
                    {
                        title: "Bodegas",
                        icon: "store",
                        to: { name: "ListaBodegas" }
                    },
                    {
                        title: "Cupones",
                        icon: "local_play",
                        to: { name: "ListaCupones" }
                    },
                    // {
                    //     title: "Etiquetas",
                    //     icon: "event_note",
                    //     to: { name: "ListaEtiqueta" }
                    // }
                ]
            });
        }
        if (rootGetters["auth/userIsSeller"]) {
            return (menuItem = {
                items1: [],
                items: [{
                        title: "Carros de Compras",
                        icon: "shopping_cart",
                        to: { name: "carts" }
                    },
                    {
                        title: "Clientes",
                        icon: "face",
                        to: { name: "ListCliente" }
                    },
                    {
                        title: "Productos",
                        icon: "description",
                        to: { name: "ListaProducto" }
                    },
                    {
                        title: "Item-productos",
                        icon: "description",
                        to: { name: "itemsProducto" }
                    },
                    {
                        title: "Categorias",
                        icon: "local_offer",
                        to: { name: "ListaCategoria" }
                    }
                ]
            });
        }
        return items;
    }
};

export const actions = {
    replacePrimaryDrawer({ commit }, payload) {
        commit(types.REPLACE_PRIMARY_DRAWER, payload);
    }
};

export const mutations = {
    [types.REPLACE_PRIMARY_DRAWER](state, { primaryDrawer }) {
        if (primaryDrawer.model) {
            state.primaryDrawer.model = primaryDrawer.model;
        }
        if (primaryDrawer.type) {
            state.primaryDrawer.type = primaryDrawer.type;
        }
        if (primaryDrawer.clipped) {
            state.primaryDrawer.clipped = primaryDrawer.clipped;
        }
        if (primaryDrawer.floating) {
            state.primaryDrawer.floating = primaryDrawer.floating;
        }
        if (primaryDrawer.mini) {
            state.primaryDrawer.mini = primaryDrawer.mini;
        }
    }
};