import * as types from "../mutation-types";
import courierAPI from "@/api/delivery";
export const state = {
    couriers: [],
    loadingCouriers: false,
    courier: null,
    showModalDeleteCourier: false
};

export const actions = {
    getCouriers({ commit }, payload) {
        commit(types.REPLACE_LOADING_COURIERS, { status: true });
        return new Promise((resolve, reject) => {
            courierAPI
                .get(payload)
                .then(response => {
                    const couriers = response.data.data;

                    commit(types.REPLACE_LOADING_COURIERS, { status: false });
                    commit(types.REPLACE_COURIERS, { couriers });

                    resolve(response);
                })
                .catch(error => {
                    commit(types.REPLACE_LOADING_COURIERS, { status: false });
                    reject(error);
                });
        });
    },

    getCourier({ commit }, payload) {
        return new Promise((resolve, reject) => {
            courierAPI
                .getById(payload)
                .then(response => {
                    const courier = response.data.data;
                    commit(types.REPLACE_CURRENT_COURIER, { courier });
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    updateCourier({ commit }, payload) {
        return new Promise((resolve, reject) => {
            courierAPI
                .put(payload)
                .then(response => {
                    this._vm.$notify.success({
                        title: "Info",
                        message: "El courier ha sido actualizado con éxito."
                    });
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    createCourier({ commit }, payload) {
        return new Promise((resolve, reject) => {
            courierAPI
                .post(payload)
                .then(response => {
                    this._vm.$notify.success({
                        title: "Etiqueta",
                        message: "El courier ha sido creado con éxito."
                    });

                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    replaceCurrentCourier({ commit }, payload) {
        commit(types.REPLACE_CURRENT_COURIER, payload);
    },
    replaceShowModalDeleteCourier({ commit }, payload) {
        commit(types.REPLACE_SHOW_MODAL_DELETE_COURIER, payload);
    },
    deleteCourier({ commit }, payload) {
        return new Promise((resolve, reject) => {
            courierAPI
                .delete(payload)
                .then(response => {
                    this._vm.$notify.success({
                        title: "Cupon",
                        message: "El courier ha sido eliminado con éxito."
                    });
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    }
};

export const mutations = {
    [types.REPLACE_LOADING_COURIERS](state, { status }) {
        state.loadingCouriers = status;
    },
    [types.REPLACE_COURIERS](state, { couriers }) {
        state.couriers = couriers;
    },
    [types.REPLACE_CURRENT_COURIER](state, { courier }) {
        state.courier = courier;
    },
    [types.REPLACE_SHOW_MODAL_DELETE_COURIER](state, { status }) {
        state.showModalDeleteCourier = status;
    }
};