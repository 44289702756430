import * as types from "../mutation-types";
import cartAPI from "@/api/cart";

export const state = {
  carros: [],
  loadingCarros: false,
  showModalDetailCart: false
};

export const actions = {
  createCart({ commit }, payload) {
    return new Promise((resolve, reject) => {
      cartAPI
        .post(payload)
        .then(response => {
          this._vm.$notify.success({
            title: "Etiqueta",
            message: "El carro ha sido creado con éxito."
          });
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  createNotification({ commit }, payload) {
    return new Promise((resolve, reject) => {
      cartAPI
        .sendNotification(payload)
        .then(response => {
          this._vm.$notify.success({
            title: "Notificacion",
            message: "La notificacion ha sido enviado con éxito."
          });
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  createPago({ commit }, payload) {
    return new Promise((resolve, reject) => {
      cartAPI
        .postPago(payload)
        .then(response => {
          this._vm.$notify.success({
            title: "Etiqueta",
            message: "El carro ha sido pagado con éxito."
          });
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  createDescuento({ commit }, payload) {
    return new Promise((resolve, reject) => {
      cartAPI
        .postDescuento(payload)
        .then(response => {
          this._vm.$notify.success({
            title: "Descuento",
            message: "El descuento creado éxito."
          });
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  replaceShowModalDetailCart({ commit }, payload) {
    commit(types.REPLACE_SHOW_MODAL_DETAIL_CART, payload);
  },
  getCarros({ commit }, payload) {
    commit(types.REPLACE_LOADING_CARROS, { status: true });
    return new Promise((resolve, reject) => {
      cartAPI
        .get(payload)
        .then(response => {
          const carros = response.data.data;

          commit(types.REPLACE_LOADING_CARROS, { status: false });
          commit(types.REPLACE_CARROS, { carros });

          resolve(response);
        })
        .catch(error => {
          commit(types.REPLACE_LOADING_CARROS, { status: false });
          reject(error);
        });
    });
  }
};

export const mutations = {
  [types.REPLACE_LOADING_CARROS](state, { status }) {
    state.loadingCarros = status;
  },
  [types.REPLACE_CARROS](state, { carros }) {
    state.carros = carros;
  },
  [types.REPLACE_SHOW_MODAL_DETAIL_CART](state, { status }) {
    state.showModalDetailCart = status;
  }
};
