import axios from "axios";
import { baseUrlAPI } from "@/config/api";

const HOST = baseUrlAPI;

export default {
  get(payload = {}) {
    const params = payload.params || {};
    return new Promise((resolve, reject) => {
      axios({
        url: `${HOST}/etapas`,
        method: "get",
        params
      })
        .then(response => resolve(response))
        .catch(error => reject(error));
    });
  },
  getById(payload = {}) {
    const etapaId = payload.etapaId || "";

    return new Promise((resolve, reject) => {
      axios({
        url: `${HOST}/etapas/${etapaId}`,
        method: "get"
      })
        .then(response => resolve(response))
        .catch(error => reject(error));
    });
  },
  put(payload = {}) {
    const etapaId = payload.etapaId;
    const data = payload.data || {};

    return new Promise((resolve, reject) => {
      axios({
        url: `${HOST}/etapas/${etapaId}`,
        method: "patch",
        data
      })
        .then(response => resolve(response))
        .catch(error => reject(error));
    });
  },
  post(payload = {}) {
    const data = payload.data || {};
    return new Promise((resolve, reject) => {
      axios({
        url: `${HOST}/etapas`,
        method: "post",
        data
      })
        .then(response => resolve(response))
        .catch(error => reject(error));
    });
  },
  delete(payload = {}) {
    const etapaId = payload.etapaId;
    return new Promise((resolve, reject) => {
      axios({
        url: `${HOST}/etapas/${etapaId}`,
        method: "delete"
      })
        .then(response => resolve(response))
        .catch(error => reject(error));
    });
  },
  sendEmailStep(payload = {}) {
    console.log(payload);
    const id = payload.id;
    const etapa = payload.etapa;
    return new Promise((resolve, reject) => {
      axios({
        url: `${HOST}/carros/${id}/etapas/correos?etapa_id=${etapa}`,
        method: "get"
      })
        .then(response => resolve(response))
        .catch(error => reject(error));
    });
  },
  sendDespacho(payload = {}) {
    const data = payload.data || {};
    const id = payload.id;
    return new Promise((resolve, reject) => {
      axios({
        url: `${HOST}/carros/${id}/etapas/despacho `,
        method: "patch",
        data
      })
        .then(response => resolve(response))
        .catch(error => reject(error));
    });
  },
  postRetroceder(payload = {}) {
    const data = payload.data || {};
    const id = payload.id;
    return new Promise((resolve, reject) => {
      axios({
        url: `${HOST}/carros/${id}/etapas/retroceder`,
        method: "post",
        data
      })
        .then(response => resolve(response))
        .catch(error => reject(error));
    });
  }
};
