import * as types from "../mutation-types";
import reportesAPI from "@/api/reportes";

export const state = {
  reportesItem: [],
  reporteClientes: [],
  reporteCiudades: [],
  reporteStocks: [],
  reporteStock: [],
  loadingReporteStock: false,
  loadingReporteItem: false,
  loadingReporteClientes: false,
  loadingReporteCiudades: false,
  loadingReporteStocks: false
};

export const actions = {
  getClientes({ commit }, payload) {
    commit(types.REPLACE_LOADING_REPORTE_CLIENTES, { status: true });
    return new Promise((resolve, reject) => {
      reportesAPI
        .getClientes(payload)
        .then(response => {
          const reporteClientes = response.data.data;
          commit(types.REPLACE_LOADING_REPORTE_CLIENTES, { status: false });
          commit(types.REPLACE_REPORTE_CLIENTES, { reporteClientes });
          resolve(response);
        })
        .catch(error => {
          commit(types.REPLACE_LOADING_REPORTE_CLIENTES, { status: false });

          reject(error);
        });
    });
  },
  getProductosItems({ commit }, payload) {
    commit(types.REPLACE_LOADING_REPORTE_ITEM, { status: true });
    return new Promise((resolve, reject) => {
      reportesAPI
        .getProductosItems(payload)
        .then(response => {
          const reporteItems = response.data.data;
          commit(types.REPLACE_LOADING_REPORTE_ITEM, { status: false });
          commit(types.REPLACE_REPORTE_ITEMS, { reporteItems });
          resolve(response);
        })
        .catch(error => {
          commit(types.REPLACE_LOADING_REPORTE_ITEM, { status: false });
          reject(error);
        });
    });
  },
  getReporteCiudades({ commit }, payload) {
    commit(types.REPLACE_LOADING_REPORTE_CIUDADES, { status: true });
    return new Promise((resolve, reject) => {
      reportesAPI
        .getReporteCiudades(payload)
        .then(response => {
          const reporteCiudades = response.data.data;
          commit(types.REPLACE_LOADING_REPORTE_CIUDADES, { status: false });
          commit(types.REPLACE_REPORTE_CIUDADES, { reporteCiudades });
          resolve(response);
        })
        .catch(error => {
          commit(types.REPLACE_LOADING_REPORTE_CIUDADES, { status: false });
          reject(error);
        });
    });
  },
  getStocks({ commit }, payload) {
    commit(types.REPLACE_LOADING_REPORTE_SIN_STOCKS, { status: true });
    return new Promise((resolve, reject) => {
      reportesAPI
        .getStocks(payload)
        .then(response => {
          const reporteStocks = response.data.data;
          commit(types.REPLACE_LOADING_REPORTE_SIN_STOCKS, { status: false });
          commit(types.REPLACE_REPORTE_SIN_STOCKS, { reporteStocks });
          resolve(response);
        })
        .catch(error => {
          commit(types.REPLACE_LOADING_REPORTE_SIN_STOCKS, { status: false });
          reject(error);
        });
    });
  },
  getStock({ commit }, payload) {
    commit(types.REPLACE_LOADING_REPORTE_STOCK, { status: true });
    return new Promise((resolve, reject) => {
      reportesAPI
        .getStock(payload)
        .then(response => {
          const reporteStock = response.data.data;
          commit(types.REPLACE_LOADING_REPORTE_STOCK, { status: false });
          commit(types.REPLACE_REPORTE_STOCK, { reporteStock });
          resolve(response);
        })
        .catch(error => {
          commit(types.REPLACE_LOADING_REPORTE_STOCK, { status: false });
          reject(error);
        });
    });
  }
};

export const mutations = {
  [types.REPLACE_REPORTE_CLIENTES](state, { reporteClientes }) {
    state.reporteClientes = reporteClientes;
  },
  [types.REPLACE_LOADING_REPORTE_CLIENTES](state, { status }) {
    state.loadingReporteClientes = status;
  },
  [types.REPLACE_LOADING_REPORTE_ITEM](state, { status }) {
    state.loadingReporteItem = status;
  },
  [types.REPLACE_REPORTE_ITEMS](state, { reporteItems }) {
    state.reporteItems = reporteItems;
  },
  [types.REPLACE_LOADING_REPORTE_CIUDADES](state, { status }) {
    state.loadingReporteCiudades = status;
  },
  [types.REPLACE_REPORTE_CIUDADES](state, { reporteCiudades }) {
    state.reporteCiudades = reporteCiudades;
  },
  [types.REPLACE_LOADING_REPORTE_SIN_STOCKS](state, { status }) {
    state.loadingReporteStocks = status;
  },
  [types.REPLACE_REPORTE_SIN_STOCKS](state, { reporteStocks }) {
    state.reporteStocks = reporteStocks;
  },
  [types.REPLACE_LOADING_REPORTE_STOCK](state, { status }) {
    state.loadingReporteStock = status;
  },
  [types.REPLACE_REPORTE_STOCK](state, { reporteStock }) {
    state.reporteStock = reporteStock;
  }
};
