import axios from "axios";
import { baseUrlAPI } from "@/config/api";

const HOST = baseUrlAPI;
export default {
  post(payload = {}) {
    const data = payload.data || {};
    return new Promise((resolve, reject) => {
      axios({
        url: `${HOST}/carros`,
        method: "post",
        data
      })
        .then(response => resolve(response))
        .catch(error => reject(error));
    });
  },
  sendNotification(payload = {}) {
    const data = payload.data || {};
    return new Promise((resolve, reject) => {
      axios({
        url: `${HOST}/carros/notificaciones`,
        method: "post",
        data
      })
        .then(response => resolve(response))
        .catch(error => reject(error));
    });
  },
  postDescuento(payload = {}) {
    const data = payload.data || {};
    return new Promise((resolve, reject) => {
      axios({
        url: `${HOST}/descuentos`,
        method: "post",
        data
      })
        .then(response => resolve(response))
        .catch(error => reject(error));
    });
  },
  postPago(payload = {}) {
    const data = payload.data || {};
    const id = payload.id || {};
    return new Promise((resolve, reject) => {
      axios({
        url: `${HOST}/carros/${id}/checkout`,
        method: "post",
        data
      })
        .then(response => resolve(response))
        .catch(error => reject(error));
    });
  },
  get(payload = {}) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${HOST}/carros/recientes`,
        method: "get"
      })
        .then(response => resolve(response))
        .catch(error => reject(error));
    });
  },
};
