import axios from "axios";
import { baseUrlAPI } from "@/config/api";

const HOST = baseUrlAPI;

export default {
  get(payload = {}) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${HOST}/configuraciones`,
        method: "get"
      })
        .then(response => resolve(response))
        .catch(error => reject(error));
    });
  },
  getById(payload = {}) {
    const configId = payload.configId || "";

    return new Promise((resolve, reject) => {
      axios({
        url: `${HOST}/configuraciones/${configId}`,
        method: "get"
      })
        .then(response => resolve(response))
        .catch(error => reject(error));
    });
  },
	put(payload = {}) {
    const configId = payload.configId;
    const data = payload.data || {};

    return new Promise((resolve, reject) => {
      axios({
        url: `${HOST}/configuraciones/${configId}`,
        method: "patch",
        data
      })
        .then(response => resolve(response))
        .catch(error => reject(error));
    });
  },
};
