import * as types from "../mutation-types";
import etapasAPI from "@/api/etapas";

export const state = {
  etapas: [],
  loadingEtapas: false,
  currentEtapa: null,
  showModalDeleteEtapa: false
};

export const actions = {
  getEtapas({ commit }, payload) {
    commit(types.REPLACE_LOADING_ETAPAS, { status: true });
    return new Promise((resolve, reject) => {
      etapasAPI
        .get(payload)
        .then(response => {
          const etapas = response.data.data;

          commit(types.REPLACE_LOADING_ETAPAS, { status: false });
          commit(types.REPLACE_ETAPAS, { etapas });

          resolve(response);
        })
        .catch(error => {
          commit(types.REPLACE_LOADING_ETAPAS, { status: false });
          reject(error);
        });
    });
  },

  getEtapa({ commit }, payload) {
    return new Promise((resolve, reject) => {
      etapasAPI
        .getById(payload)
        .then(response => {
          const etapa = response.data.data;

          commit(types.REPLACE_CURRENT_ETAPA, { etapa });

          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  deleteEtapa({ commit }, payload) {
    return new Promise((resolve, reject) => {
      etapasAPI
        .delete(payload)
        .then(response => {
          this._vm.$notify.success({
            title: "Etapa",
            message: "La etapa ha sido eliminado con éxito."
          });
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  replaceCurrentEtapa({ commit }, payload) {
    commit(types.REPLACE_CURRENT_ETAPA, payload);
  },
  replaceShowModalDeleteEtapa({ commit }, payload) {
    commit(types.REPLACE_SHOW_MODAL_DELETE_ETAPA, payload);
  },
  updateEtapa({ commit }, payload) {
    return new Promise((resolve, reject) => {
      etapasAPI
        .put(payload)
        .then(response => {
          this._vm.$notify.success({
            title: "Etapa",
            message: "El etapa ha sido actualizado con éxito."
          });

          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  createEtapa({ commit }, payload) {
    return new Promise((resolve, reject) => {
      etapasAPI
        .post(payload)
        .then(response => {
          this._vm.$notify.success({
            title: "Etapa",
            message: "El etapa ha sido actualizado con éxito."
          });

          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  sendEmailStep({ commit }, payload) {
    return new Promise((resolve, reject) => {
      etapasAPI
        .sendEmailStep(payload)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  sendDespacho({ commit }, payload) {
    return new Promise((resolve, reject) => {
      etapasAPI
        .sendDespacho(payload)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  postRetroceder({ commit }, payload) {
    return new Promise((resolve, reject) => {
      etapasAPI
        .postRetroceder(payload)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  }
};

export const mutations = {
  [types.REPLACE_LOADING_ETAPAS](state, { status }) {
    state.loadingEtapas = status;
  },
  [types.REPLACE_ETAPAS](state, { etapas }) {
    state.etapas = etapas;
  },
  [types.REPLACE_CURRENT_ETAPA](state, { etapa }) {
    state.currentEtapa = etapa;
  },
  [types.REPLACE_SHOW_MODAL_DELETE_ETAPA](state, { status }) {
    state.showModalDeleteEtapa = status;
  }
};
