import axios from "axios";
import { baseUrlAPI } from "@/config/api";

const HOST = baseUrlAPI;

export default {
  get(payload = {}) {
    const params = payload.params || {};
    return new Promise((resolve, reject) => {
      axios({
        url: `${HOST}/descuentos`,
        method: "get",
        params
      })
        .then(response => resolve(response))
        .catch(error => reject(error));
    });
  },
  getById(payload = {}) {
    const descuentoId = payload.descuentoId || "";
    return new Promise((resolve, reject) => {
      axios({
        url: `${HOST}/descuentos/${descuentoId}`,
        method: "get"
      })
        .then(response => resolve(response))
        .catch(error => reject(error));
    });
  },
  put(payload = {}) {
    const descuentoId = payload.descuentoId;
    const data = payload.data || {};

    return new Promise((resolve, reject) => {
      axios({
        url: `${HOST}/descuentos/${descuentoId}`,
        method: "patch",
        data
      })
        .then(response => resolve(response))
        .catch(error => reject(error));
    });
  },
  delete(payload = {}) {
    const descuentoId = payload.descuentoId;
    return new Promise((resolve, reject) => {
      axios({
        url: `${HOST}/descuentos/${descuentoId}`,
        method: "delete"
      })
        .then(response => resolve(response))
        .catch(error => reject(error));
    });
  }
};
