import portadaAPI from "@/api/portada";
import * as types from "../mutation-types";
export const state = {
    portada: null,
    showModalDeletePortada: false,
    portadas: [],
    loadingPortadas: false
};
export const actions = {
    createPortada({ commit }, payload) {
        return new Promise((resolve, reject) => {
            portadaAPI.post(payload)
                .then(response => {
                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
    getPortada({ commit }, payload) {
        return new Promise((resolve, reject) => {
            portadaAPI
                .getById(payload)
                .then(response => {
                    const portada = response.data.data;

                    commit(types.REPLACE_CURRENT_PORTADA, { portada });

                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    getPortadas({ commit }, payload) {
        commit(types.REPLACE_LOADING_PORTADAS, { status: true });
        return new Promise((resolve, reject) => {
            portadaAPI
                .get(payload)
                .then(response => {
                    const portadas = response.data.data;

                    commit(types.REPLACE_LOADING_PORTADAS, { status: false });
                    commit(types.REPLACE_PORTADAS, { portadas });

                    resolve(response);
                })
                .catch(error => {
                    commit(types.REPLACE_LOADING_PORTADAS, { status: false });
                    reject(error);
                });
        });
    },
    deletePortada({ commit }, payload) {
        return new Promise((resolve, reject) => {
            portadaAPI
                .delete(payload)
                .then(response => {
                    this._vm.$notify.success({
                        title: "Info",
                        message: "La Imagen ha sido eliminado con éxito."
                    });
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    replaceCurrentPortada({ commit }, payload) {
        commit(types.REPLACE_CURRENT_PORTADA, payload);
    },
    replaceShowModalDeletePortada({ commit }, payload) {
        commit(types.REPLACE_SHOW_MODAL_DELETE_PORTADA, payload);
    },
    updatePortada({ commit }, payload) {
        return new Promise((resolve, reject) => {
            portadaAPI
                .put(payload)
                .then(response => {
                    this._vm.$notify.success({
                        title: "Info",
                        message: "El Item ha sido actualizado con éxito."
                    });

                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    }
}

export const mutations = {
    [types.REPLACE_CURRENT_PORTADA](state, { portada }) {
        state.portada = portada;
    },
    [types.REPLACE_SHOW_MODAL_DELETE_PORTADA](state, { status }) {
        state.showModalDeletePortada = status;
    },
    [types.REPLACE_LOADING_PORTADAS](state, { status }) {
        state.loadingPortadas = status;
    },
    [types.REPLACE_PORTADAS](state, { portadas }) {
        state.portadas = portadas;
    },
};