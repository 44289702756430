import axios from "axios";
import { baseUrlAPI } from "@/config/api";

const HOST = baseUrlAPI;

export default {
  getProductosItems(payload = {}) {
    const params = payload.params || {};
    const desde = payload.desde || "";
    const hacia = payload.hacia || "";
    return new Promise((resolve, reject) => {
      axios({
        url: `${HOST}/reportes/items/carros?desde=${desde}&hacia=${hacia}`,
        method: "get",
        params
      })
        .then(response => resolve(response))
        .catch(error => reject(error));
    });
  },
  getClientes(payload = {}) {
    const params = payload.params || {};
    const desde = payload.desde || "";
    const hacia = payload.hacia || "";
    return new Promise((resolve, reject) => {
      axios({
        url: `${HOST}/reportes/clientes/carros?desde=${desde}&hacia=${hacia}`,
        method: "get",
        params
      })
        .then(response => resolve(response))
        .catch(error => reject(error));
    });
  },
  getReporteCiudades(payload = {}) {
    const params = payload.params || {};
    const desde = payload.desde || "";
    const hacia = payload.hacia || "";
    return new Promise((resolve, reject) => {
      axios({
        url: `${HOST}/reportes/ciudades/carros?desde=${desde}&hacia=${hacia}`,
        method: "get"
      })
        .then(response => resolve(response))
        .catch(error => reject(error));
    });
  },
  getStocks(payload = {}) {
    const params = payload.params || {};
    const desde = payload.desde || "";
    const hacia = payload.hacia || "";
    return new Promise((resolve, reject) => {
      axios({
        url: `${HOST}/reportes/items/sin-stock?desde=${desde}&hacia=${hacia}`,
        method: "get",
        params
      })
        .then(response => resolve(response))
        .catch(error => reject(error));
    });
  },
  getStock(payload = {}) {
    const params = payload.params || {};
    return new Promise((resolve, reject) => {
      axios({
        url: `${HOST}/reportes/items/stock`,
        method: "get",
        params
      })
        .then(response => resolve(response))
        .catch(error => reject(error));
    });
  }
};
