import * as types from "../mutation-types";
import cartAPI from "@/api/detailCart";

export const state = {
  detailCart: []
};

export const actions = {
  getDetailCart({ commit }, payload) {
    return new Promise((resolve, reject) => {
      cartAPI
        .get(payload)
        .then(response => {
          const detailCart = response.data.data;
          commit(types.REPLACE_DETAIL_CART, { detailCart });
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  nextEtapa({ commit }, payload) {
    return new Promise((resolve, reject) => {
      cartAPI
        .postEtapa(payload)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  }
};

export const mutations = {
  [types.REPLACE_DETAIL_CART](state, { detailCart }) {
    state.detailCart = detailCart;
  }
};
