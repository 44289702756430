import axios from "axios";
import { baseUrlAPI } from "@/config/api";

const HOST = baseUrlAPI;

export default {
	uploadImagen (payload = {}) {
    const formData = payload.formData
    return new Promise((resolve, reject) => {
      axios({
        url: `${HOST}/imagenes/upload`,
        method: 'post',
        data: formData,
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },
  getById(payload = {}) {
    const imagenId = payload.imagenId || "";
    return new Promise((resolve, reject) => {
      axios({
        url: `${HOST}/imagen-productos/${imagenId}`,
        method: "get"
      })
        .then(response => resolve(response))
        .catch(error => reject(error));
    });
  },
  put(payload = {}) {
        const imagenId = payload.imagenId;
        const data = payload.data || {};
    
        return new Promise((resolve, reject) => {
          axios({
            url: `${HOST}/imagen-productos/${imagenId}`,
            method: "post",
            data,
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          })
            .then(response => resolve(response))
            .catch(error => reject(error));
        });
      },
    delete(payload = {}) {
        const imagenId = payload.imagenId;
        return new Promise((resolve, reject) => {
          axios({
            url: `${HOST}/imagen-productos/${imagenId}`,
            method: "delete",
          })
            .then(response => resolve(response))
            .catch(error => reject(error));
        });
      },
}