import axios from "axios";
import { baseUrlAPI } from "@/config/api";

const HOST = baseUrlAPI;

export default {
	post (payload = {}) {
    const data = payload.data;
    return new Promise((resolve, reject) => {
      axios({
        url: `${HOST}/portadas`,
        method: 'post',
        data,
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
	},
	get(payload = {}) {
		const params = payload.params || {};
		return new Promise((resolve, reject) => {
			axios({
				url: `${HOST}/portadas`,
				method: 'get',
				params
			})
				.then(response => resolve(response))
				.catch(error => reject(error));
		});
	},
  getById(payload = {}) {
    const portadaId = payload.portadaId || "";
    return new Promise((resolve, reject) => {
      axios({
        url: `${HOST}/portadas/${portadaId}`,
        method: "get"
      })
        .then(response => resolve(response))
        .catch(error => reject(error));
    });
  },
  put(payload = {}) {
        const portadaId = payload.portadaId;
        const data = payload.data || {};
    
        return new Promise((resolve, reject) => {
          axios({
            url: `${HOST}/portadas/${portadaId}`,
            method: "post",
            data,
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          })
            .then(response => resolve(response))
            .catch(error => reject(error));
        });
      },
    delete(payload = {}) {
        const portadaId = payload.portadaId;
        return new Promise((resolve, reject) => {
          axios({
            url: `${HOST}/portadas/${portadaId}`,
            method: "delete",
          })
            .then(response => resolve(response))
            .catch(error => reject(error));
        });
      },
}