<template>
  <div id="app">
    <Loading ref="loading" />
    <transition name="page" mode="out-in">
      <component :is="layout" v-if="layout" />
    </transition>
  </div>
</template>

<script>
import Loading from "@/components/Loading";

// Load layout components dynamically.
const requireContext = require.context("@/layouts", false, /.*\.vue$/);

const layouts = requireContext
  .keys()
  .map(file => [file.replace(/(^.\/)|(\.vue$)/g, ""), requireContext(file)])
  .reduce((components, [name, component]) => {
    components[name] = component.default || component;
    return components;
  }, {});

export default {
  el: "#app",

  metaInfo() {
    const appName = "Panel Administrador";

    return {
      title: appName,
      titleTemplate: `%s - ${appName}`
    };
  },

  components: {
    Loading
  },

  data() {
    return {
      layout: null,
      defaultLayout: "default"
    };
  },

  mounted() {
    this.$loading = this.$refs.loading;
  },

  methods: {
    /**
     * Set the application layout.
     *
     * @param {String} layout
     */
    setLayout(layout) {
      if (!layout || !layouts[layout]) {
        layout = this.defaultLayout;
      }

      this.layout = layouts[layout];
    }
  }
};
</script>
<style>
#app{
  background: #f2f5f8;
}
::-webkit-scrollbar {
     width: 2px !important;
     height: 6px !important;  
}

::-webkit-scrollbar-track {
     background: #fff !;
}

::-webkit-scrollbar-thumb {
    background-color: #999 !important;
    border-radius: 10px !important;
}
.v-card{
  border-radius: 14px !important;    
}
/* .v-card .container {
  padding:40px 2% 0 !important;
} */
.v-toolbar__content{
  border-radius: 14px !important;
  height: 75px !important;
}

</style>

