import axios from "axios";
import { baseUrlAPI } from "@/config/api";

const HOST = baseUrlAPI;
export default {
  getRating(payload = {}) {
    const id = payload.productoId || "";
    return new Promise((resolve, reject) => {
      axios({
        url: `${HOST}/productos/${id}/ratings`,
        method: "get"
      })
        .then(response => resolve(response))
        .catch(error => reject(error));
    });
  },
  delete(payload = {}) {
    const ratingId = payload.ratingId;
    const data = {
      aprobado: false
    }

    return new Promise((resolve, reject) => {
      axios({
        url: `${HOST}/ratings/${ratingId}/aprobar`,
        method: "patch",
        data
      })
        .then(response => resolve(response))
        .catch(error => reject(error));
    });
  },
};
