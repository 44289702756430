import * as types from "../mutation-types";
import cuponesAPI from "@/api/cupones";

export const state = {
  cupones: [],
  loadingCupones: false,
  currentCupon: null,
  showModalDeleteCupon: false
};

export const actions = {
  getCupones({ commit }, payload) {
    commit(types.REPLACE_LOADING_CUPONES, { status: true });
    return new Promise((resolve, reject) => {
      cuponesAPI
        .get(payload)
        .then(response => {
          const cupones = response.data.data;

          commit(types.REPLACE_LOADING_CUPONES, { status: false });
          commit(types.REPLACE_CUPONES, { cupones });

          resolve(response);
        })
        .catch(error => {
          commit(types.REPLACE_LOADING_CUPONES, { status: false });
          reject(error);
        });
    });
  },

  getCupon({ commit }, payload) {
    return new Promise((resolve, reject) => {
      cuponesAPI
        .getById(payload)
        .then(response => {
          const cupon = response.data.data;
          commit(types.REPLACE_CURRENT_CUPON, { cupon });
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  deleteCupon({ commit }, payload) {
    return new Promise((resolve, reject) => {
      cuponesAPI
        .delete(payload)
        .then(response => {
          this._vm.$notify.success({
            title: "Cupon",
            message: "El cupon ha sido eliminado con éxito."
          });
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  replaceCurrentCupon({ commit }, payload) {
    commit(types.REPLACE_CURRENT_CUPON, payload);
  },
  replaceShowModalDeleteCupon({ commit }, payload) {
    commit(types.REPLACE_SHOW_MODAL_DELETE_CUPON, payload);
  },
  updateCupon({ commit }, payload) {
    return new Promise((resolve, reject) => {
      cuponesAPI
        .put(payload)
        .then(response => {
          this._vm.$notify.success({
            title: "Cupon",
            message: "El cupon ha sido actualizado con éxito."
          });

          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  createCupon({ commit }, payload) {
    return new Promise((resolve, reject) => {
      cuponesAPI
        .post(payload)
        .then(response => {
          this._vm.$notify.success({
            title: "Cupon",
            message: "El cupon ha sido actualizado con éxito."
          });

          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  }
};

export const mutations = {
  [types.REPLACE_LOADING_CUPONES](state, { status }) {
    state.loadingEtapas = status;
  },
  [types.REPLACE_CUPONES](state, { cupones }) {
    state.cupones = cupones;
  },
  [types.REPLACE_CURRENT_CUPON](state, { cupon }) {
    state.currentCupon = cupon;
  },
  [types.REPLACE_SHOW_MODAL_DELETE_CUPON](state, { status }) {
    state.showModalDeleteCupon = status;
  }
};
