import * as types from "../mutation-types";
import clientesAPI from "@/api/clientes";

export const state = {
  clientes: [],
  loadingClientes: false,
  cliente: null,
  showModalDeleteCliente: false,
  direcciones: [],
  stadistics: null,
  cars: [],
  carsItems: [],
  showModalDetailCliente: false
};

export const actions = {
  getClientes({ commit }, payload) {
    commit(types.REPLACE_LOADING_CLIENTES, { status: true });
    return new Promise((resolve, reject) => {
      clientesAPI
        .get(payload)
        .then(response => {
          const clientes = response.data.data;
          commit(types.REPLACE_LOADING_CLIENTES, { status: false });
          commit(types.REPLACE_CLIENTES, { clientes });
          resolve(response);
        })
        .catch(error => {
          commit(types.REPLACE_LOADING_CLIENTES, { status: false });
          reject(error);
        });
    });
  },

  getCliente({ commit }, payload) {
    return new Promise((resolve, reject) => {
      clientesAPI
        .getById(payload)
        .then(response => {
          const cliente = response.data.data;
          commit(types.REPLACE_CURRENT_CLIENTE, { cliente });
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  getDirecciones({ commit }, payload) {
    return new Promise((resolve, reject) => {
      clientesAPI
        .getDirecciones(payload)
        .then(response => {
          const direcciones = response.data.data;
          commit(types.REPLACE_CURRENT_DIRECCIONES, { direcciones });
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  getStadistics({ commit }, payload) {
    return new Promise((resolve, reject) => {
      clientesAPI
        .getStadistics(payload)
        .then(response => {
          const stadistics = response.data.data;
          commit(types.REPLACE_CURRENT_STADISTICS, { stadistics });
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  getCars({ commit }, payload) {
    return new Promise((resolve, reject) => {
      clientesAPI
        .getCars(payload)
        .then(response => {
          const cars = response.data.data;
          commit(types.REPLACE_CURRENT_CARS, { cars });
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  getCarsItems({ commit }, payload) {
    return new Promise((resolve, reject) => {
      clientesAPI
        .getCarsItems(payload)
        .then(response => {
          const carsItems = response.data.data;
          commit(types.REPLACE_CURRENT_CARS_ITEMS, { carsItems });
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  deleteCliente({ commit }, payload) {
    return new Promise((resolve, reject) => {
      clientesAPI
        .delete(payload)
        .then(response => {
          this._vm.$notify.success({
            title: "Cliente",
            message: "La cliente ha sido eliminado con éxito."
          });
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  replaceCurrentCliente({ commit }, payload) {
    commit(types.REPLACE_CURRENT_CLIENTE, payload);
  },
  replaceShowModalDeleteCliente({ commit }, payload) {
    commit(types.REPLACE_SHOW_MODAL_DELETE_CLIENTE, payload);
  },
  replaceShowModalDetailCliente({ commit }, payload) {
    commit(types.REPLACE_SHOW_MODAL_DETAIL_CLIENTE, payload);
  },
  updateCliente({ commit }, payload) {
    return new Promise((resolve, reject) => {
      clientesAPI
        .put(payload)
        .then(response => {
          this._vm.$notify.success({
            title: "Cliente",
            message: "El cliente ha sido actualizado con éxito."
          });
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  createCliente({ commit }, payload) {
    return new Promise((resolve, reject) => {
      clientesAPI
        .post(payload)
        .then(response => {
          this._vm.$notify.success({
            title: "Cliente",
            message: "El cliente ha sido actualizado con éxito."
          });

          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  }
};

export const mutations = {
  [types.REPLACE_LOADING_CLIENTES](state, { status }) {
    state.loadingClientes = status;
  },
  [types.REPLACE_CLIENTES](state, { clientes }) {
    state.clientes = clientes;
  },
  [types.REPLACE_CURRENT_CLIENTE](state, { cliente }) {
    state.cliente = cliente;
  },
  [types.REPLACE_CURRENT_DIRECCIONES](state, { direcciones }) {
    state.direcciones.push(direcciones);
  },
  [types.REPLACE_SHOW_MODAL_DELETE_CLIENTE](state, { status }) {
    state.showModalDeleteCliente = status;
  },
  [types.REPLACE_SHOW_MODAL_DETAIL_CLIENTE](state, { status }) {
    state.showModalDetailCliente = status;
  },
  [types.REPLACE_CURRENT_STADISTICS](state, { stadistics }) {
    state.stadistics = stadistics;
  },
  [types.REPLACE_CURRENT_CARS](state, { cars }) {
    state.cars = cars;
  },
  [types.REPLACE_CURRENT_CARS_ITEMS](state, { carsItems }) {
    state.carsItems = carsItems;
  }
};
