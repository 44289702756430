import * as types from "../mutation-types";
import ratingAPI from "@/api/rating";

export const state = {
    ratings: [],
    loadingRatings: false,
    currentRating: null,
    showModalDeleteRating: false
};

export const actions = {
    getRatings({ commit }, payload) {
        commit(types.REPLACE_LOADING_RATINGS, { status: true });
        return new Promise((resolve, reject) => {
            ratingAPI
                .getRating(payload)
                .then(response => {
                    const ratings = response.data.data;

                    commit(types.REPLACE_LOADING_RATINGS, { status: false });
                    commit(types.REPLACE_RATINGS, { ratings });

                    resolve(response);
                })
                .catch(error => {
                    commit(types.REPLACE_LOADING_RATINGS, { status: false });
                    reject(error);
                });
        });
    },

    getEtapa({ commit }, payload) {
        return new Promise((resolve, reject) => {
            ratingAPI
                .getById(payload)
                .then(response => {
                    const rating = response.data.data;

                    commit(types.REPLACE_CURRENT_RATING, { rating });
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    deleteRating({ commit }, payload) {
        return new Promise((resolve, reject) => {
            ratingAPI
                .delete(payload)
                .then(response => {
                    this._vm.$notify.success({
                        title: "Comentarios",
                        message: "El comentario ha sido eliminado con éxito."
                    });
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    replaceCurrentRating({ commit }, payload) {
        commit(types.REPLACE_CURRENT_RATING, payload);
    },
    replaceShowModalDeleteRating({ commit }, payload) {
        commit(types.REPLACE_SHOW_MODAL_DELETE_RATING, payload);
    },
    updateRating({ commit }, payload) {
        return new Promise((resolve, reject) => {
            ratingAPI
                .put(payload)
                .then(response => {
                    this._vm.$notify.success({
                        title: "Comentario",
                        message: "El Comentario ha sido actualizado con éxito."
                    });

                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    createRating({ commit }, payload) {
        return new Promise((resolve, reject) => {
            ratingAPI
                .post(payload)
                .then(response => {
                    this._vm.$notify.success({
                        title: "Comentario",
                        message: "El comentario ha sido actualizado con éxito."
                    });

                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    deleteRating({ commit }, payload) {
        return new Promise((resolve, reject) => {
            ratingAPI
                .delete(payload)
                .then(response => {
                    this._vm.$notify.success({
                        title: "Info",
                        message: "El Rating ha sido desactivado con éxito."
                    });
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
};

export const mutations = {
    [types.REPLACE_LOADING_RATINGS](state, { status }) {
        state.loadingRatings = status;
    },
    [types.REPLACE_RATINGS](state, { ratings }) {
        state.ratings = ratings;
    },
    [types.REPLACE_CURRENT_RATING](state, { rating }) {
        state.currentRating = rating;
    },
    [types.REPLACE_SHOW_MODAL_DELETE_RATING](state, { status }) {
        state.showModalDeleteRating = status;
    }
};