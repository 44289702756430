import axios from "axios";
import { baseUrlAPI } from "@/config/api";

const HOST = baseUrlAPI;

export default {
  get(payload = {}) {
    const params = payload.params || {};
    return new Promise((resolve, reject) => {
      axios({
        url: `${HOST}/etiquetas`,
        method: "get",
        params
      })
        .then(response => resolve(response))
        .catch(error => reject(error));
    });
  },
  getById(payload = {}) {
    const etiquetaId = payload.etiquetaId || "";

    return new Promise((resolve, reject) => {
      axios({
        url: `${HOST}/etiquetas/${etiquetaId}`,
        method: "get"
      })
        .then(response => resolve(response))
        .catch(error => reject(error));
    });
  },
  put(payload = {}) {
    const etiquetaId = payload.etiquetaId;
    const data = payload.data || {};

    return new Promise((resolve, reject) => {
      axios({
        url: `${HOST}/etiquetas/${etiquetaId}`,
        method: "put",
        data
      })
        .then(response => resolve(response))
        .catch(error => reject(error));
    });
  },
  post(payload = {}) {
    const data = payload.data || {};
    return new Promise((resolve, reject) => {
      axios({
        url: `${HOST}/etiquetas`,
        method: "post",
        data
      })
        .then(response => resolve(response))
        .catch(error => reject(error));
    });
  },
  delete(payload = {}) {
    const etiquetaId = payload.etiquetaId;
    console.log(etiquetaId);
    return new Promise((resolve, reject) => {
      axios({
        url: `${HOST}/etiquetas/${etiquetaId}`,
        method: "DELETE"
      })
        .then(response => resolve(response))
        .catch(error => reject(error));
    });
  }
};
