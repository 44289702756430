import * as types from "../mutation-types";
import cityAPI from "@/api/city";

export const state = {
    cities: [],
    loadingCities: false,
    city: null,
    showModalDeleteCity: false
};

export const actions = {
    getCities({ commit }, payload) {
        commit(types.REPLACE_LOADING_CITIES, { status: true });
        return new Promise((resolve, reject) => {
            cityAPI
                .get(payload)
                .then(response => {
                    const cities = response.data.data;

                    commit(types.REPLACE_LOADING_CITIES, { status: false });
                    commit(types.REPLACE_CITIES, { cities });

                    resolve(response);
                })
                .catch(error => {
                    commit(types.REPLACE_LOADING_CITIES, { status: false });
                    reject(error);
                });
        });
    },

    getCity({ commit }, payload) {
        return new Promise((resolve, reject) => {
            cityAPI
                .getById(payload)
                .then(response => {
                    const city = response.data.data;

                    commit(types.REPLACE_CURRENT_CITY, { city });

                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    updateCity({ commit }, payload) {
        return new Promise((resolve, reject) => {
            cityAPI
                .put(payload)
                .then(response => {
                    this._vm.$notify.success({
                        title: "Info",
                        message: "La ciudad ha sido actualizado con éxito."
                    });

                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    createCity({ commit }, payload) {
        return new Promise((resolve, reject) => {
            cityAPI
                .post(payload)
                .then(response => {
                    this._vm.$notify.success({
                        title: "Etiqueta",
                        message: "La ciudad ha sido creado con éxito."
                    });

                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    replaceCurrentCity({ commit }, payload) {
        commit(types.REPLACE_CURRENT_CITY, payload);
    },
    replaceShowModalDeleteCity({ commit }, payload) {
        commit(types.REPLACE_SHOW_MODAL_DELETE_CITY, payload);
    },
    deleteCity({ commit }, payload) {
        return new Promise((resolve, reject) => {
            cityAPI
                .delete(payload)
                .then(response => {
                    this._vm.$notify.success({
                        title: "Info",
                        message: "la ciudad ha sido eliminado con éxito."
                    });
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
};

export const mutations = {
    [types.REPLACE_LOADING_CITIES](state, { status }) {
        state.loadingPrecios = status;
    },
    [types.REPLACE_CITIES](state, { cities }) {
        state.cities = cities;
    },
    [types.REPLACE_CURRENT_CITY](state, { city }) {
        state.city = city;
    },
    [types.REPLACE_SHOW_MODAL_DELETE_CITY](state, { status }) {
        state.showModalDeleteCity = status;
    }
};