import axios from "axios";
import { baseUrlAPI } from "@/config/api";

const HOST = baseUrlAPI;

export default {
  get(payload = {}) {
    const params = payload.params || {};
    return new Promise((resolve, reject) => {
      axios({
        url: `${HOST}/productos`,
        method: "get",
        params
      })
        .then(response => resolve(response))
        .catch(error => reject(error));
    });
  },
  getById(payload = {}) {
    const productoId = payload.productoId || "";
    return new Promise((resolve, reject) => {
      axios({
        url: `${HOST}/productos/${productoId}`,
        method: "get"
      })
        .then(response => resolve(response))
        .catch(error => reject(error));
    });
  },
  put(payload = {}) {
    const productoId = payload.productoId;
    const data = payload.data || {};
    return new Promise((resolve, reject) => {
      axios({
        url: `${HOST}/productos/${productoId}`,
        method: "put",
        data
      })
        .then(response => resolve(response))
        .catch(error => reject(error));
    });
  },
  post(payload = {}) {
    const data = payload.data || {};
    return new Promise((resolve, reject) => {
      axios({
        url: `${HOST}/productos`,
        method: "post",
        data
      })
        .then(response => resolve(response))
        .catch(error => reject(error));
    });
  },
  postImage(payload = {}) {
    const productoId = payload.productoId;
    const data = payload.data || {};
    return new Promise((resolve, reject) => {
      axios({
        url: `${HOST}/productos/${productoId}/imagenes`,
        method: "post",
        headers: {},
        data
      })
        .then(response => resolve(response))
        .catch(error => reject(error));
    });
  },
  getEtiquetas(payload = {}) {
    const id = payload.productoId || "";

    return new Promise((resolve, reject) => {
      axios({
        url: `${HOST}/productos/${id}/etiquetas`,
        method: "get"
      })
        .then(response => resolve(response))
        .catch(error => reject(error));
    });
  },
  putEtiqueta(payload = {}) {
    const etiquetaId = payload.etiquetaId;
    const data = payload.data || {};
    return new Promise((resolve, reject) => {
      axios({
        url: `${HOST}/etiqueta-productos/${etiquetaId}`,
        method: "patch",
        data
      })
        .then(response => resolve(response))
        .catch(error => reject(error));
    });
  },
  deleteItem(payload = {}) {
    const itemId = payload.itemId;
    return new Promise((resolve, reject) => {
      axios({
        url: `${HOST}/item-productos/${itemId}`,
        method: "delete"
      })
        .then(response => resolve(response))
        .catch(error => reject(error));
    });
  },
  getEtiqueta(payload = {}) {
    const id = payload.etiquetaId || "";

    return new Promise((resolve, reject) => {
      axios({
        url: `${HOST}/etiqueta-productos/${id}`,
        method: "get"
      })
        .then(response => resolve(response))
        .catch(error => reject(error));
    });
  },
  deleteEtiqueta(payload = {}) {
    const etiquetaId = payload.etiquetaId;
    return new Promise((resolve, reject) => {
      axios({
        url: `${HOST}/etiqueta-productos/${etiquetaId}`,
        method: "delete"
      })
        .then(response => resolve(response))
        .catch(error => reject(error));
    });
  },
  postEtiquetas(payload = {}) {
    //const id = payload.productoId;
    const data = payload.data || {};
    return new Promise((resolve, reject) => {
      axios({
        url: `${HOST}/etiqueta-productos`,
        method: "post",
        data
      })
        .then(response => resolve(response))
        .catch(error => reject(error));
    });
  },
  getImages(payload = {}) {
    const productoId = payload.productoId;
    const params = payload.params || {};
    return new Promise((resolve, reject) => {
      axios({
        url: `${HOST}/productos/${productoId}/imagenes`,
        method: "get",
        params
      })
        .then(response => resolve(response))
        .catch(error => reject(error));
    });
  },
  getAtributos(payload = {}) {
    const productoId = payload.productoId;
    const params = payload.params || {};
    return new Promise((resolve, reject) => {
      axios({
        url: `${HOST}/productos/${productoId}/atributos`,
        method: "get",
        params
      })
        .then(response => resolve(response))
        .catch(error => reject(error));
    });
  },
  getAtributo(payload = {}) {
    const atributoId = payload.atributoId;
    const params = payload.params || {};
    return new Promise((resolve, reject) => {
      axios({
        url: `${HOST}/campo-productos/${atributoId}`,
        method: "get",
        params
      })
        .then(response => resolve(response))
        .catch(error => reject(error));
    });
  },
  deleteAtributo(payload = {}) {
    const atributoId = payload.atributoId;
    return new Promise((resolve, reject) => {
      axios({
        url: `${HOST}/campo-productos/${atributoId}`,
        method: "delete"
      })
        .then(response => resolve(response))
        .catch(error => reject(error));
    });
  },
  postAtributos(payload = {}) {
    const productoId = payload.productoId;
    const data = payload.data || {};
    return new Promise((resolve, reject) => {
      axios({
        url: `${HOST}/productos/${productoId}/atributos`,
        method: "post",
        data
      })
        .then(response => resolve(response))
        .catch(error => reject(error));
    });
  },
  putATributo(payload = {}) {
    const atributoId = payload.atributoId;
    const data = payload.data || {};
    return new Promise((resolve, reject) => {
      axios({
        url: `${HOST}/campo-productos/${atributoId}`,
        method: "patch",
        data
      })
        .then(response => resolve(response))
        .catch(error => reject(error));
    });
  },
  getItems(payload = {}) {
    const productoId = payload.productoId;
    const params = payload.params || {};
    return new Promise((resolve, reject) => {
      axios({
        url: `${HOST}/productos/${productoId}/items`,
        method: "get",
        params
      })
        .then(response => resolve(response))
        .catch(error => reject(error));
    });
  },
  postItems(payload = {}) {
    const productoId = payload.productoId;
    const data = payload.data || {};
    return new Promise((resolve, reject) => {
      axios({
        url: `${HOST}/productos/${productoId}/items`,
        method: "post",
        data
      })
        .then(response => resolve(response))
        .catch(error => reject(error));
    });
  },
  delete(payload = {}) {
    const productoId = payload.productoId;
    return new Promise((resolve, reject) => {
      axios({
        url: `${HOST}/productos/${productoId}`,
        method: "delete"
      })
        .then(response => resolve(response))
        .catch(error => reject(error));
    });
  }
};
