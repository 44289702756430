import Vue from 'vue';
import './plugins/vuetify/vuetify';
import './plugins/element/element';
import { abilitiesPlugin } from '@casl/vue';
import App from './App.vue';
import router from './router';
import store from '@/store/index';
import './registerServiceWorker';
import '@/plugins/axios';
import HighchartsVue from "highcharts-vue";
import { ability } from '@/config/ability';
import CKEditor from 'ckeditor4-vue';

Vue.use( CKEditor );
Vue.use(abilitiesPlugin, ability, HighchartsVue);

Vue.config.productionTip = false;

/* eslint-disable no-new */
new Vue({
  store,
  router,
  ...App,
});
/* eslint-enable no-new */
