import axios from "axios";
import { baseUrlAPI } from "@/config/api";

const HOST = baseUrlAPI;

export default {
	get(payload = {}) {
    const itemId = payload.itemId || "";
    return new Promise((resolve, reject) => {
      axios({
        url: `${HOST}/item-productos/${itemId}/precios`,
        method: "get"
      })
        .then(response => resolve(response))
        .catch(error => reject(error));
    });
  },
  getById(payload = {}) {
    const precioId = payload.precioId || "";

    return new Promise((resolve, reject) => {
      axios({
        url: `${HOST}/precios/${precioId}`,
        method: "get"
      })
        .then(response => resolve(response))
        .catch(error => reject(error));
    });
  },
  put(payload = {}) {
    const precioId = payload.precioId;
    const data = payload.data || {};

    return new Promise((resolve, reject) => {
      axios({
        url: `${HOST}/precios/${precioId}`,
        method: "patch",
        data
      })
        .then(response => resolve(response))
        .catch(error => reject(error));
    });
  },
  post(payload = {}) {
        const itemId = payload.itemId;
        const data = payload.data || {};
    
        return new Promise((resolve, reject) => {
          axios({
            url: `${HOST}/item-productos/${itemId}/precios`,
            method: "post",
            data
          })
            .then(response => resolve(response))
            .catch(error => reject(error));
        });
      },
      delete(payload = {}) {
        const precioId = payload.precioId;
        return new Promise((resolve, reject) => {
          axios({
            url: `${HOST}/precios/${precioId}`,
            method: "delete",
          })
            .then(response => resolve(response))
            .catch(error => reject(error));
        });
      },
}